import React from "react";

import AflrStripe from "../AflrStripe/AflrStripe";
import "./steps.css";

export default function Step7(props) {
  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <div>
      <div className="background-stripe"></div>

      <AflrStripe
        clientSecretState={props.clientSecretState}
        paymentStatusState={props.paymentStatusState}
        nextStep={props.nextStep}
        email={props.values.email}
        values={props.values}
      />
      <button
        className="button-secondary-aflr"
        style={{ color: "#fff", marginLeft: "5%" }}
        onClick={back}
      >
        Back
      </button>
      <br />
    </div>
  );
}
