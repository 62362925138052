import React from "react";

const App = () => {
  return (
    <div className="App">
      <svg
        width="112"
        height="130"
        viewBox="0 0 112 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M77.5899 53.2453C77.5899 53.2453 76.4699 67.6317 85.5899 73.168C85.5899 73.168 46.8699 89.4264 35.9199 70.1646C24.9699 50.9027 51.6199 2.08726 70.6999 10.1564C80.5399 13.33 77.5899 53.2453 77.5899 53.2453Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88.85 94.5022C82.35 89.0761 66.2701 85.7623 66.4301 86.0326C65.6801 84.8913 65.3401 82.4385 65.2101 79.8056C65.096 76.6044 65.156 73.3993 65.39 70.2047H46.39C46.39 70.2047 46.5701 72.4272 46.6201 75.2103C46.7001 79.1348 46.55 84.2206 45.36 86.0026C45.27 86.0026 31.19 89.5767 23.36 95.183C17.14 99.6481 11.0601 128.11 11.0601 128.11H99.82C99.82 128.11 96.87 101.19 88.85 94.5022Z"
          fill="white"
        />
        <path
          d="M64.83 79.4353C61.25 75.6209 49.17 75.2705 46.98 75.2705C46.98 73.1682 46.83 71.3761 46.77 70.6153H46.97C49.6358 72.3087 52.7278 73.2078 55.885 73.2078C59.0422 73.2078 62.1342 72.3087 64.8 70.6153H64.99C64.7902 73.5508 64.7368 76.4944 64.83 79.4353Z"
          fill="#E19696"
        />
        <path
          d="M29.1699 107.077C29.1699 107.077 29.2998 93.9717 30.4799 91.0584L39.5799 87.7446C39.5799 87.7446 35.1199 102.892 43.4899 105.385C49.2299 107.087 67.7199 106.286 70.6999 105.204C76.1799 103.202 74.1099 90.9884 72.2999 87.3242L81.5199 90.4177C82.3661 95.637 82.8473 100.909 82.9599 106.196C84.9925 109.496 86.0689 113.296 86.0689 117.173C86.0689 121.05 84.9925 124.851 82.9599 128.151H29.0599C23.3499 121.723 29.1699 107.077 29.1699 107.077Z"
          fill="#E19696"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88.85 94.5022C82.35 89.0761 66.2701 85.7623 66.4301 86.0326C65.6801 84.8913 65.3401 82.4385 65.2101 79.8056C65.096 76.6044 65.156 73.3993 65.39 70.2047H46.39C46.39 70.2047 46.5701 72.4272 46.6201 75.2103C46.7001 79.1348 46.55 84.2206 45.36 86.0026C45.27 86.0026 31.19 89.5767 23.36 95.183C17.14 99.6481 11.0601 128.11 11.0601 128.11H99.82C99.82 128.11 96.87 101.19 88.85 94.5022Z"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.84 38.9191C32.84 38.9191 28.59 37.9179 26.96 40.9814C25.49 43.7245 26.81 51.6736 34.15 53.1352C41.49 54.5969 37.22 40.4008 32.84 38.9191Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.1698 45.0662C33.1698 45.0662 31.4198 42.173 29.0898 41.8426"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.5 43.1138C30.6135 43.7749 29.9223 44.664 29.5 45.6867"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.17 38.9191C79.17 38.9191 83.42 37.9179 85.05 40.9814C86.52 43.7245 85.2 51.6736 77.86 53.1352C70.52 54.5969 74.82 40.4008 79.17 38.9191Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.8999 45.0662C78.8999 45.0662 80.6499 42.173 82.9799 41.8426"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M80.54 43.1138C81.4266 43.7749 82.1177 44.664 82.54 45.6867"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.8198 38.4486C78.4398 30.5497 76.6598 22.8609 68.3198 19.2568C64.3604 17.7091 60.128 16.9836 55.8798 17.1244C36.9298 17.1244 33.5698 27.5462 32.9698 38.3886C32.8498 40.551 32.8398 42.7335 32.8398 44.8559C32.8398 54.2065 38.3898 65.0689 46.3698 70.2047C49.1647 72.1299 52.4773 73.1606 55.8698 73.1606C59.2623 73.1606 62.575 72.1299 65.3698 70.2047C73.3698 65.0789 78.9098 54.1865 78.9098 44.8559C78.9298 42.7535 78.9298 40.5911 78.8198 38.4486Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.29 39.9304C48.29 40.3105 48.1773 40.6822 47.9664 40.9983C47.7554 41.3144 47.4555 41.5607 47.1047 41.7062C46.7539 41.8517 46.3678 41.8898 45.9954 41.8156C45.6229 41.7414 45.2808 41.5584 45.0123 41.2895C44.7438 41.0207 44.5609 40.6782 44.4868 40.3053C44.4128 39.9325 44.4508 39.546 44.5961 39.1948C44.7414 38.8435 44.9875 38.5433 45.3033 38.3321C45.619 38.1209 45.9902 38.0082 46.37 38.0082C46.6225 38.0068 46.8727 38.0557 47.1063 38.1518C47.3398 38.2479 47.552 38.3895 47.7306 38.5682C47.9091 38.747 48.0505 38.9594 48.1465 39.1932C48.2425 39.427 48.2913 39.6776 48.29 39.9304Z"
          fill="#2C2A3F"
        />
        <path
          d="M67.09 39.9304C67.09 40.3105 66.9774 40.6822 66.7665 40.9983C66.5555 41.3144 66.2556 41.5607 65.9048 41.7062C65.5539 41.8517 65.1679 41.8898 64.7954 41.8156C64.423 41.7414 64.0809 41.5584 63.8124 41.2895C63.5439 41.0207 63.361 40.6782 63.2869 40.3053C63.2128 39.9325 63.2509 39.546 63.3962 39.1948C63.5415 38.8435 63.7876 38.5433 64.1033 38.3321C64.4191 38.1209 64.7903 38.0082 65.17 38.0082C65.4225 38.0068 65.6728 38.0557 65.9063 38.1518C66.1399 38.2479 66.3521 38.3895 66.5306 38.5682C66.7092 38.747 66.8505 38.9594 66.9466 39.1932C67.0426 39.427 67.0914 39.6776 67.09 39.9304Z"
          fill="#2C2A3F"
        />
        <path
          d="M58.43 34.7845C57.8538 37.9537 57.6891 41.184 57.94 44.3954C58.43 48.9006 59.54 50.8828 58.43 51.2532C57.201 51.4482 55.949 51.4482 54.72 51.2532"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.29 33.9235C48.29 33.9235 42.91 32.622 41.06 34.7845C41.06 34.7845 40.56 32.0013 43.9 31.9413C47.24 31.8812 48.29 33.9235 48.29 33.9235Z"
          fill="#2C2A3F"
        />
        <path
          d="M63.46 33.9235C63.46 33.9235 68.8399 32.622 70.6899 34.7845C70.6899 34.7845 71.1899 32.0013 67.8499 31.9413C64.5099 31.8812 63.46 33.9235 63.46 33.9235Z"
          fill="#2C2A3F"
        />
        <path
          d="M62.5099 17.1243C62.5099 17.1243 69.2399 24.1322 76.7799 27.7564C78.1565 31.1499 78.8367 34.7862 78.7799 38.4485C78.7799 38.4485 88.3499 26.595 85.5699 17.1243C83.9099 11.4679 73.8799 -4.74057 54.6799 3.86921C51.0399 5.52109 63.5899 12.7193 62.5099 17.1243Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M62.5099 17.1245C62.5099 17.1245 55.3299 27.1358 46.7699 27.7565C34.5299 28.6175 32.9799 50.2221 35.3499 64.7986C37.1999 76.2116 31.4999 77.0726 31.4999 77.0726C31.7583 72.0311 30.5307 67.0248 27.9699 62.6762C26.1899 59.5827 20.9099 52.9652 20.2099 38.3686C18.2899 -2.07735 62.5099 -7.56359 62.5099 17.1245Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.25 58.7216C49.25 58.7216 58.48 60.0932 62.52 57.4902"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M53.4199 42.5934C53.4179 44.3275 52.9025 46.0221 51.9387 47.463C50.975 48.9039 49.6062 50.0264 48.0053 50.6887C46.4045 51.3509 44.6434 51.5232 42.9448 51.1837C41.2462 50.8441 39.6862 50.0081 38.4621 48.7812C37.238 47.5543 36.4047 45.9916 36.0675 44.2907C35.7303 42.5897 35.9044 40.8269 36.5677 39.225C37.231 37.6231 38.3538 36.2539 39.7942 35.2907C41.2345 34.3275 42.9278 33.8134 44.6599 33.8134C45.8111 33.8134 46.9511 34.0406 48.0145 34.4819C49.078 34.9233 50.0441 35.5702 50.8577 36.3856C51.6713 37.2011 52.3163 38.169 52.756 39.2342C53.1956 40.2994 53.4212 41.4409 53.4199 42.5934V42.5934Z"
          stroke="#E19696"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M77.28 42.5934C77.28 44.3303 76.7654 46.0283 75.8013 47.4723C74.8372 48.9164 73.4669 50.0418 71.8638 50.706C70.2607 51.3702 68.4968 51.5435 66.7953 51.2039C65.0939 50.8644 63.5312 50.0271 62.3051 48.7982C61.079 47.5693 60.2446 46.004 59.9073 44.3001C59.57 42.5963 59.7451 40.8307 60.4104 39.2265C61.0757 37.6224 62.2014 36.2518 63.6449 35.2882C65.0884 34.3247 66.785 33.8114 68.52 33.8134C69.6712 33.8134 70.8111 34.0406 71.8746 34.482C72.9381 34.9233 73.9042 35.5702 74.7178 36.3856C75.5313 37.2011 76.1764 38.169 76.616 39.2342C77.0557 40.2994 77.2813 41.4409 77.28 42.5934V42.5934Z"
          stroke="#E19696"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M1 128.141H110.46"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default App;
