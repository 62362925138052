import React, { useState } from "react";
import Conrad from "../../assets/img/voices/conrad";
import Brad from "../../assets/img/voices/brad";
import Joanna from "../../assets/img/voices/joanna";
import Susan from "../../assets/img/voices/susan";
import Avatar from "../../assets/img/voices/avatar.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Paper from "@material-ui/core/Paper";
import AflrCard from "../AflrReusableComponents/AflrCard";
import { useOrientation } from "../Hooks";
import EmailPopup from "./EmailPopup";
import AflrWidget from "./../AflrReusableComponents/AflrWidget";
import { useAdvancedOrientation } from "../Hooks";

//Custom styling for wrapper component
const useStyles = makeStyles((theme) => ({
  paper: {
    border: "none",
    margin: "3%",
    marginTop: "-1%",
    padding: "0px"
  }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    padding: "5px",
    border: "none",
    boxShadow: "none",
    borderRadius: "10px !important"
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  selected: {
    // border: "5px solid rgba(0, 205, 199, 0.5)",
    borderRadius: "10px !important",
    // boxShadow: "none",
    backgroundColor: "rgba(0, 205, 199, 0.5) !important"
  }
}))(ToggleButton);

// speaker choices
const cards = [
  {
    title: "Conrad (UK)",
    tag1: "Gentle",
    tag2: "Narrative",
    imgSrc: Conrad,
    speakerName: "conrad",
    apiKey: "a66483fb374daaef2c09ca1d24ee152a"
  },
  {
    title: "Brad (US)",
    tag1: "Friendly",
    tag2: "Clear",
    imgSrc: Brad,
    speakerName: "brad",
    apiKey: "884354eb56db3323cbce63a5e177ecac"
  },
  {
    title: "Joanna (US)",
    tag1: "Joyous",
    tag2: "Friendly",
    imgSrc: Joanna,
    speakerName: "joanna",
    apiKey: "d979871f68a9e367eb3a5df8be7c4bf4"
  },
  {
    title: "Susan (US)",
    tag1: "Scientific",
    tag2: "Balanced",
    imgSrc: Susan,
    speakerName: "susan",
    apiKey: "ac575e3eecf0fa410518c2d3a2e7209f"
  }
];

const widthThresoldOne = 800;
const widthThresoldTwo = 1200;

export default function ChooseSpeaker(props) {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const { values, handleChange } = props;
  const { currentCondition } = useAdvancedOrientation(widthThresoldOne, widthThresoldTwo, [
    {
      display: "grid",
      gridTemplateColumns: "25% 25% 25% 25% "
    },
    {
      display: "grid",
      gridColumnGap: "6%",
      margin: "0% 8%",
      gridTemplateColumns: "48% 48%"
    },
    {
      display: "grid",
      gridTemplateColumns: "80%",
      marginLeft: "16%"
    }
  ]);

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          exclusive
          onChange={handleChange("speaker")}
          value={values.speaker}
          style={currentCondition}
        >
          {cards.map((card) => (
            <StyledToggleButton key={card.speakerName} value={card.title}>
              <div className="speaker-container">
                <br />
                <card.imgSrc />
                <div className="speaker-name">{card.title}</div>
                <div className="speaker-tag-container ">
                  <div className="speaker-tag">{card.tag1}</div>
                  <div className="speaker-tag">{card.tag2}</div>
                </div>
                <br />
                <AflrWidget apiKey={card.apiKey} />
              </div>
            </StyledToggleButton>
          ))}
          <br></br>
        </StyledToggleButtonGroup>
        <div className="aflr-title">or interested in cloning your own voice?</div>
      </Paper>
      <div style={{ marginBottom: "-4%", marginTop: "-1%" }}>
        <Modal show={showModal} setShow={setShowModal} />
        <button className="button-aflr" style={{ width: 120 }} onClick={() => setShowModal(true)}>
          Yes
        </button>
      </div>
    </>
  );
}

const Modal = ({ show, setShow }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <div className="email-text" style={{ padding: "5%" }}>
          Thanks for letting us know. Voice cloning will be available soon. For now please choose
          one of the 4 speaker options.
        </div>
        <button className="button-aflr" style={{ width: 120 }} onClick={() => setShow(false)}>
          Okay
        </button>
      </section>
    </div>
  );
};
