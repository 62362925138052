import React, { useLayoutEffect, useState } from "react";
import Unbounce from "../../assets/img/platforms/unbounce.png";
import Leadpages from "../../assets/img/platforms/leadpages.png";
import Wordpress from "../../assets/img/platforms/wordpress.png";
import Shopify from "../../assets/img/platforms/shopify.png";
import Instapage from "../../assets/img/platforms/instapage.png";
import Clickfunnels from "../../assets/img/platforms/clickfunnels.png";
import Hubspot from "../../assets/img/platforms/hubspot.png";
import html5 from "../../assets/img/platforms/html5.png";
import webflow from "../../assets/img/platforms/webflow.png";
import other from "../../assets/img/platforms/other.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Paper from "@material-ui/core/Paper";
import { useAdvancedOrientation } from "../Hooks";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    padding: "5px",
    border: "none",
    boxShadow: "none",
    borderRadius: "10px !important"
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  selected: {
    // border: "5px solid rgba(0, 205, 199, 0.5)",
    borderRadius: "10px !important",
    // boxShadow: "none",
    backgroundColor: "rgba(0, 205, 199, 0.5) !important"
  },
  hover: {
    // border: "5px solid rgba(0, 205, 199, 0.5)",
    borderRadius: "10px !important",
    // boxShadow: "none",
    backgroundColor: "rgba(0, 205, 199, 0.5) !important"
  }
}))(ToggleButton);

// speaker choices
const platformActive = [
  {
    title: "Unbounce",
    imgSrc: Unbounce,
    imgAlt: "Unbounce"
  },
  {
    title: "Leadpages",
    imgSrc: Leadpages,
    imgAlt: "Leadpages"
  },
  {
    title: "Instapage",
    imgSrc: Instapage,
    imgAlt: "Instapage"
  },
  {
    title: "Clickfunnels",
    imgSrc: Clickfunnels,
    imgAlt: "Clickfunnels"
  },
  {
    title: "Wordpress",
    imgSrc: Wordpress,
    imgAlt: "Wordpress"
  },
  {
    title: "Shopify",
    imgSrc: Shopify,
    imgAlt: "Shopify"
  },
  {
    title: "Hubspot",
    imgSrc: Hubspot,
    imgAlt: "Hubspot"
  },
  {
    title: "webflow",
    imgSrc: webflow,
    imgAlt: "webflow"
  },
  {
    title: "html5",
    imgSrc: html5,
    imgAlt: "html5"
  },
  {
    title: "other",
    imgSrc: other,
    imgAlt: "other"
  }
];
const platformInctive = [
  {
    title: "Wordpress",
    imgSrc: Wordpress,
    imgAlt: "Wordpress"
  },
  {
    title: "Shopify",
    imgSrc: Shopify,
    imgAlt: "Shopify"
  },
  {
    title: "Hubspot",
    imgSrc: "",
    imgAlt: "Hubspot"
  }
];

const widthThresoldOne = 800;
const widthThresoldTwo = 1500;

export default function ChoosePlatform(props) {
  const { values, handleChange, disabled, setDisabled } = props;
  const { currentCondition } = useAdvancedOrientation(widthThresoldOne, widthThresoldTwo, [
    {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr"
    },
    {
      display: "grid",
      gridTemplateColumns: "1fr  1fr 1fr"
    },
    {
      display: "grid",
      gridTemplateColumns: "1fr",
      width: "80%",
      marginLeft: "12%"
    }
  ]);

  const errorChecker = (e) => {
    if (values.platform === "" && values.platform === undefined) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  return (
    <>
      <div className="platform-grid">
        <StyledToggleButtonGroup
          style={currentCondition}
          exclusive
          defaultValue={"Clickfunnels"}
          onChange={handleChange("platform")}
          value={values.platform}
        >
          {platformActive.map((option) => (
            <StyledToggleButton
              style={{ margin: "8px" }}
              className="platform-active"
              key={option.imgAlt}
              value={option.imgAlt}
              onClick={errorChecker}
            >
              <img
                className="platform-img"
                src={option.imgSrc}
                alt={option.imgAlt}
                style={{ objectFit: "cover", height: "100%" }}
              />
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </div>
    </>
  );
}
