import React, { useState } from "react";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";

import Button from "../CustomButtons/Button";
import "./steps.css";
import ChooseMusic from "components/AflrUserInputComponents/ChooseMusic";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

export default function Step4(props) {
  const { values } = props;
  const { userName, businessName } = values;
  const [classes, setClasses] = useState("error-hidden");
  const saveAndContinue = (e) => {
    if (props.values.music !== "" && props.values.music !== undefined) {
      e.preventDefault();
      if (!props.values.userId) {
        e.preventDefault();
      }
      props.nextStep();
    } else {
      setClasses("error-shown");

      setTimeout(() => setClasses("error-hidden"), 3000);
    }
  };
  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <div>
      <TitleAndInfo
        title={"Now, add a background sound experience to your script:"}
        description={`Tip: ${userName}, this is the secret sauce that will bring your audio experience to life! Choose the sound experience that fits best with ${businessName}.`}
      />

      <ChooseMusic handleChange={props.handleChange} values={props.values} />

      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons">
        <button className="button-secondary-aflr" onClick={back}>
          Back
        </button>

        <div className={classes}>Please choose a music background</div>

        <button className="button-aflr" onClick={saveAndContinue}>
          Next
        </button>
      </div>
    </div>
  );
}
