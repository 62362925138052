import React from "react";
import { studioURL } from "components/Hooks";
import "./AflrReusable.css";

export default function PaymentCard(props) {
  const { values, setSelected, userValues } = props;
  const { zero, title, priceMonth, apiKey } = values;
  const texts = [
    values.nAudioStories,
    values.nMonthlyplays,
    values.nSpeakers,
    values.analytics,
    values.branding,
    values.advertising,
    values.customerSupport
  ];
  return (
    <div className="payment-choice">
      {!zero && (
        <div onClick={() => setSelected(apiKey)}>
          <div className="prize-title">{title}</div>

          <div className="prize-month">€{priceMonth}</div>

          <div className="payment-type">/month</div>

          <button className="button-aflr button-stripex">Get started</button>

          <div className="description-item">
            {texts.map((text) => (
              <div>&#10003;&nbsp;{text}</div>
            ))}
          </div>
        </div>
      )}
      {zero && (
        <div
          onClick={() =>
            window.open(
              studioURL + "/register?apikey=" + userValues.userId + "&email=" + userValues.email,
              "_self"
            )
          }
        >
          <div className="prize-title">{title}</div>

          <div className="prize-month">€{priceMonth}</div>

          <div className="payment-type">/month</div>

          <button className="button-aflr button-stripex">Apply now!</button>

          <div className="description-item">
            {texts.map((text) => (
              <div>&#10003;&nbsp;{text}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
