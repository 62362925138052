import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

// speaker choices
const goals = [
  {
    value: "leadGen",
    label: "Generate new leads"
  },
  {
    value: "salesIncrease",
    label: "Reactivate existing contacts through email campaigns"
  },
  {
    value: "engagement",
    label: "Convert leads into customers"
  }
];

export default function ChooseTemplate(props) {
  const { values, handleChange } = props;

  return (
    <>
      <div className="dropdown-labels ">My goal is to </div>
      <div className="styled-select">
        <select value={values.userGoal} onChange={handleChange("userGoal")}>
          {goals.map((option) => (
            <option key={option.value} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      <br></br>
      <br></br>
    </>
  );
}
