import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./AflrStripe.css";
import ChoosePricing from "components/AflrUserInputComponents/ChoosePricing";
import Button from "components/CustomButtons/Button.js";

const pathname =
  window.location.pathname.slice(-1) === "/"
    ? window.location.pathname.slice(1)
    : window.location.pathname;

let currentUrl = window.location.protocol + "//" + window.location.hostname + pathname;

if (window.location.hostname === "localhost") currentUrl = currentUrl + ":" + window.location.port;
const successUrl = currentUrl + "/success";
const failUrl = currentUrl + "/fail";
const promise = loadStripe("pk_test_MsTG7q4kQ4hFoi4P8Q0koCv700aJTSlJYB");

export default function AflrStripe({ clientSecretState, email, values }) {
  const [clientSecret, setClientSecret] = clientSecretState;
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    function fetchPaymentInfo() {
      fetch("https://audiofunnel.aflr.io/create_payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          customer_email: email,
          success_url: successUrl,
          fail_url: failUrl,
          price_id: selected
        })
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.error) alert(data.error);
          else setClientSecret(data.id);
        });
    }
    if (selected) fetchPaymentInfo();
  }, [selected]);

  useEffect(() => {
    async function goToStripe() {
      const stripe = await promise;
      const payload = await stripe.redirectToCheckout({
        sessionId: clientSecret
      });
      if (payload.error) {
        alert(payload.error);
      }
    }
    if (clientSecret !== "") goToStripe();
  }, [clientSecret]);

  return (
    <div>
      <ChoosePricing selected={selected} setSelected={setSelected} values={values} />

      <div
        style={{
          fontSize: "16px",
          paddingBottom: "1%",
          fontWeight: "400",
          color: "#fff",
          textAlign: "center"
        }}
      >
        You will be redirected to Stripe.com to proceed with the payment
      </div>
    </div>
  );
}
