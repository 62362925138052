import React, { useState } from "react";
import Button from "../CustomButtons/Button";
import "./steps.css";
import ChooseTemplate from "components/AflrUserInputComponents/ChooseTemplate";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

export default function Step3(props) {
  const { values } = props;
  const { userName, businessName, speaker } = values;
  const [classes, setClasses] = useState("error-hidden");

  const saveAndContinue = (e) => {
    if (props.values.text !== "" && props.values.text !== undefined) {
      e.preventDefault();
      if (!props.values.userId) {
        e.preventDefault();
      }
      props.nextStep();
    } else {
      setClasses("error-shown");

      setTimeout(
        function () {
          setClasses("error-hidden");
        }.bind(this),
        3000
      );
    }
  };
  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <div>
      <TitleAndInfo
        title={`${userName}, what do you want ${speaker} to read for ${businessName}? We recommend these templates:`}
      />

      <ChooseTemplate handleChange={props.handleChange} values={props.values} />

      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons">
        <button className="button-secondary-aflr" onClick={back}>
          Back
        </button>{" "}
        <div className={classes}>Please introduce a template</div>
        <button className="button-aflr" onClick={saveAndContinue}>
          Next
        </button>
      </div>
    </div>
  );
}
