import React from "react";
import "./ProgressBar.css";

const Filler = (props) => {
  return <div className="filler" style={{ width: `${(props.step * 100) / 7}%` }} />;
};

const ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler step={props.step} />
    </div>
  );
};

export default ProgressBar;
