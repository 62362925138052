import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import AflrWidget from "../AflrReusableComponents/AflrWidget";

export default function UserScript(props) {
  const { values, handleChange } = props;
  const { speaker, music } = values;
  const [open1, setOpen1] = React.useState(false);
  const [apiKey, setApiKey] = useState(undefined);

  useEffect(() => {
    switch (speaker + music) {
      case "Susan (US)" + "Gym focus":
        return setApiKey("ff9bf1aaad8ca56b7600dd6c619eabc2");
      case "Conrad (UK)" + "Gym focus":
        return setApiKey("3c876ecdb2830afc34fb94ea8149041e");
      case "Brad (US)" + "Gym focus":
        return setApiKey("53f7bf8126c9e97d0076639e1fbef9fb");
      case "Joanna (US)" + "Gym focus":
        return setApiKey("74365b0b7d9bb1a12a93293db314bdc0");
      case "Susan (US)" + "Go-go-gone":
        return setApiKey("456ff19a88e91411bdf27e2655ac99f7");
      case "Conrad (UK)" + "Go-go-gone":
        return setApiKey("313e9a7196afae43061c61d1dd924dca");
      case "Brad (US)" + "Go-go-gone":
        return setApiKey("715c1e3edfbccab12eee59fdd566a7e3");
      case "Joanna (US)" + "Go-go-gone":
        return setApiKey("0c71abac79b06450b1a134bdfa755d42 ");
      case "Susan (US)" + "Sunset Beach Café":
        return setApiKey("577578cb775a5c0df435b800074f67cf");
      case "Conrad (UK)" + "Sunset Beach Café":
        return setApiKey("c342b7acc6a07bdd57870d637a2f6d73");
      case "Brad (US)" + "Sunset Beach Café":
        return setApiKey("e09fe492c2f200ae2358414056a39266");
      case "Joanna (US)" + "Sunset Beach Café":
        return setApiKey("223d8891c62d3da8b4c46eb870f045d6");
      case "Susan (US)" + "Glass-y Mistery":
        return setApiKey("14849cdc7d9dea02e3f711504597a35b");
      case "Conrad (UK)" + "Glass-y Mistery":
        return setApiKey("74876b4688f640954e0378d78ea0d14a");
      case "Brad (US)" + "Glass-y Mistery":
        return setApiKey("e8c80554a95ceb1de708ff71bfb6c512");
      case "Joanna (US)" + "Glass-y Mistery":
        return setApiKey("5fe141fc5ecb40502debf95981394fcd");
      default:
        break;
    }
  }, []);

  const handleTooltipOpen1 = () => {
    navigator.clipboard.writeText(bundle);
    setOpen1(true);
    setTimeout(() => setOpen1(false), 2000);
  };
  const [open2, setOpen2] = React.useState(false);

  const handleTooltipOpen2 = () => {
    navigator.clipboard.writeText(widgetCode);
    setOpen2(true);
    setTimeout(() => setOpen2(false), 2000);
  };
  const bundle =
    '<script src="https://aflr-assets.s3-eu-west-1.amazonaws.com/widget/bundle.js"></script>';

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#1B2124",
      color: "#fff",
      maxWidth: 320,
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "19px",
      padding: "20px"
    },
    arrow: {
      color: "#1B2124"
    }
  }))(Tooltip);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://aflr-assets.s3-eu-west-1.amazonaws.com/widget/bundle.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const widgetCode =
    '<div id="aflr_widget" data-key="' +
    apiKey +
    '" data-iconcolor="#303030" data-logocolor="b" ></div>';
  const textAreaRef = useRef(null);

  return (
    <>
      <div className="tutorial-container">
        {/* <div className="tutorial-integration">1. Copy and paste your script:</div>
        <div className="code-container">
          <div className="code">{bundle}</div>
          <HtmlTooltip
            open={open1}
            disableFocusListener
            disableHoverListener
            placement="left"
            title="copied to clipboard!"
          >
            <FileCopyOutlinedIcon className="copy-button" onClick={handleTooltipOpen1} />
          </HtmlTooltip>
        </div>
        <div className="tutorial-integration">
          2. Create a custom html field and paste this code:
        </div>
        <div className="code-container">
          <div className="code">{widgetCode}</div>
          <HtmlTooltip
            open={open2}
            disableFocusListener
            disableHoverListener
            placement="left"
            title="copied to clipboard!"
          >
            <FileCopyOutlinedIcon className="copy-button" onClick={handleTooltipOpen2} />
          </HtmlTooltip>
        </div>

        <a
          target="_blank"
          href="https://www.notion.so/aflorithmic/Audiofunnel-Integration-f54c35b5a38945c58cab9308f57d0691"
        >
          <div
            className="tutorial-integration"
            style={{ color: "#0742B9", textDecoration: "underline" }}
          >
            Learn how to integrate our widget
          </div>
        </a> */}
        <AflrWidget apiKey={apiKey} style={{ padding: "50px", paddingBottom: "0px" }} />
      </div>
      {/* <div className="widget-container">
        <div className="widget-preview-text">
          {values.userName}, here is a preview of how your audio story will sound like
        </div>
        <div
          id="aflr_widget"
          data-key="aflr"
          data-url={widgetCode}
          data-hidefooter="true"
          className="aflr-widget"
        ></div>
      </div> */}
    </>
  );
}
