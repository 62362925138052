import React from "react";
import Button from "../CustomButtons/Button";
import "./steps.css";
import UserScript from "components/AflrUserInputComponents/UserScript";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

export default function Step6(props) {
  const saveAndContinue = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <div>
      <TitleAndInfo
        title={
          "Voilà, you’re almost done! This is how your audio story will sound and feel. Hit the play button below to find out!"
        }
      />
      <UserScript handleChange={props.handleChange} values={props.values} />

      <br></br>
      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons" style={{ gridTemplateColumns: "auto auto" }}>
        <button className="button-secondary-aflr" onClick={back}>
          Back
        </button>
        <button className="button-aflr" onClick={saveAndContinue}>
          Next
        </button>
      </div>
    </div>
  );
}
