import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Button } from "@material-ui/core";

export default function (props) {
  const { title, description } = props;

  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#1B2124",
      color: "#fff",
      maxWidth: 320,
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "15px",
      lineHeight: "19px",
      padding: "20px",
    },
    arrow: {
      color: "#1B2124",
    },
  }))(Tooltip);

  return (
    <div className="description-container">
      <div className="aflr-title">{title}</div>
      {description && (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <HtmlTooltip
              disableFocusListener
              placement="left-start"
              title={description}
            >
              <InfoOutlinedIcon fontSize="large" style={{ color: "#545454" }} />
            </HtmlTooltip>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
}
