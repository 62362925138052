import React, { useState, useLayoutEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Paper from "@material-ui/core/Paper";
import AflrCard from "../AflrReusableComponents/AflrCard";
import Card from "components/Card/Card.js";
import glassyMistery from "../../assets/img/music/glassyMystery-min.jpeg";
import goGoGone from "../../assets/img/music/goGoGone-min.jpeg";
import gymFocus from "../../assets/img/music/gymFocus-min.jpeg";
import sunsetBeachCafe from "../../assets/img/music/sunset-min.jpeg";
import { useAdvancedOrientation } from "../Hooks";
import AflrWidget from "components/AflrReusableComponents/AflrWidget";

//Custom styling for wrapper component
const useStyles = makeStyles((theme) => ({
  paper: {
    border: "none",

    marginTop: "-1%",
    padding: "0%"
  }
}));

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(1),
    padding: "5px",
    border: "none",
    boxShadow: "none",
    borderRadius: "10px !important"
  }
}))(ToggleButtonGroup);

const StyledToggleButton = withStyles((theme) => ({
  selected: {
    // border: "5px solid rgba(0, 205, 199, 0.5)",
    borderRadius: "10px !important",
    // boxShadow: "none",
    backgroundColor: "rgba(0, 205, 199, 0.5) !important"
  }
}))(ToggleButton);

const musics = [
  {
    id: 3,
    title: "Gym focus",
    description: "Reflective and focused electronic loop with gym ambience.  ",
    src:
      "https://asset-store-prod.s3-eu-west-1.amazonaws.com/audiofunnel/website/onboarding/gym-focus.mp3",
    imgSrc: gymFocus,
    imgAlt: "Gym Focus"
  },
  {
    id: 4,
    title: "Go-go-gone",
    description: "Pensive and relaxing guitar loop with runner's ambience",
    src:
      "https://asset-store-prod.s3-eu-west-1.amazonaws.com/audiofunnel/website/onboarding/go-go-gone.mp3",
    imgSrc: goGoGone,
    imgAlt: "Go Go Gone"
  },
  {
    id: 1,
    title: "Sunset Beach Café",
    description: "Relaxing ocean waves with seagulls in the background",
    src:
      "https://asset-store-prod.s3-eu-west-1.amazonaws.com/audiofunnel/website/onboarding/sunset.mp3",
    imgSrc: sunsetBeachCafe,
    imgAlt: "Sunset Beach Cafe"
  },
  {
    id: 2,
    title: "Glass-y Mistery",
    description: `Glassy sounding melody with soft chimes in the background`,
    src:
      "https://asset-store-prod.s3-eu-west-1.amazonaws.com/audiofunnel/website/onboarding/Glass-yMistery.mp3",
    imgSrc: glassyMistery,
    imgAlt: "Glass-y-Mistery"
  }
];

const widthThresoldOne = 700;
const widthThresoldTwo = 1200;

export default function ChooseMusic(props) {
  const classes = useStyles();
  const { values, handleChange } = props;
  const { currentCondition } = useAdvancedOrientation(widthThresoldOne, widthThresoldTwo, [
    {
      display: "grid",
      gridTemplateColumns: "23% 23% 23% 23% ",
      gridColumnGap: "3%",
      margin: "0% 8%"
    },
    {
      display: "grid",
      gridColumnGap: "6%",
      margin: "0% 8%",
      gridTemplateColumns: "48% 48%"
    },
    {
      display: "grid",
      gridTemplateColumns: "80%",
      marginLeft: "16%"
    }
  ]);

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <StyledToggleButtonGroup
          exclusive
          onChange={handleChange("music")}
          value={values.music}
          style={currentCondition}
        >
          {musics.map((card) => (
            <StyledToggleButton key={card.id} value={card.title}>
              <div className="speaker-container">
                <img
                  src={card.imgSrc}
                  alt={"picture"}
                  style={{
                    height: 150,
                    width: "110%",
                    maxWidth: "110%",
                    marginLeft: "-5%",
                    marginTop: "-5%",
                    padding: "0%",
                    borderRadius: "5px 5px 0px 0px",
                    objectFit: "cover"
                  }}
                />
                <div style={{ height: 150, position: "relative" }}>
                  <div className="speaker-name">{card.title}</div>
                  <div className="card-description">{card.description}</div>
                  <div>
                    <AflrWidget
                      audio={card.src}
                      apiKey="aflr_widget"
                      style={{ position: "absolute", bottom: 0, width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </StyledToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Paper>
    </>
  );
}
