import React from "react";
import Button from "../CustomButtons/Button";
import "../Steps/steps.css";

export default function Success(props) {
  const email = props;
  return (
    <div>
      <br></br> <br></br>
      <div className="aflr-title" style={{ marginBottom: "41px" }}>
        Great job!
      </div>
      <div className="aflr-title" style={{ marginBottom: "41px" }}>
        Your audio story will be produced now.
      </div>
      <div className="aflr-title" style={{ marginBottom: "69px" }}>
        Our mighty team will get in contact with you for more details. :)
      </div>
      <button className="button-aflr" style={{ width: "200px" }}>
        <a style={{ color: "white" }} href="https://audiofunnel.co/">
          Go back to the main page
        </a>
      </button>
    </div>
  );
}
