import React, { useState } from "react";
import "./steps.css";
import UserName from "components/AflrUserInputComponents/UserName";
import UserVertical from "components/AflrUserInputComponents/UserVertical";
import UserGoal from "components/AflrUserInputComponents/UserGoal";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";
// import storytelling from "../../assets/img/uiux/Storytelling.png";
import { useOrientation } from "../Hooks";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

const widthThresold = 850;

export default function Step1(props) {
  const [businessNameError, setBusinessNameError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const { orientation, isHorizontal } = useOrientation(widthThresold);
  const saveAndContinue = (e) => {
    if (
      props.values.userName !== "" &&
      props.values.businessName !== "" &&
      props.values.userName !== undefined &&
      props.values.businessName !== undefined
    ) {
      e.preventDefault();
      if (!props.values.userId) {
        e.preventDefault();
      }
      localStorage.setItem("aflr_username", props.values.userName);
      props.nextStep();
    } else {
      if (props.values.userName === "" || props.values.userName == undefined) {
        setUserNameError(true);
      } else {
        setUserNameError(false);
      }
      if (props.values.businessName === "" || props.values.businessName == undefined) {
        setBusinessNameError(true);
      } else {
        setBusinessNameError(false);
      }
      setTimeout(
        function () {
          setBusinessNameError(false);
          setUserNameError(false);
        }.bind(this),
        1500
      );
    }
  };

  return (
    <>
      <TitleAndInfo
        title={"Welcome to Audiofunnel!\nCreate your personal audio story."}
        description={
          "Using your input you will create an engaging audio experience for your business. This will help you engage better with your users. Your audio experience will come with a cool audio player to implement on your website."
        }
      />

      <div>
        <UserName
          description="Hello! my name is"
          type={"userName"}
          handleChange={props.handleChange}
          values={props.values}
          isError={userNameError}
        />
        <UserName
          description="and my business is"
          type={"businessName"}
          handleChange={props.handleChange}
          values={props.values}
          isError={businessNameError}
        />
        <UserVertical handleChange={props.handleChange} values={props.values} />
        <UserGoal handleChange={props.handleChange} values={props.values} />
      </div>

      {/* <img
          src={storytelling}
          alt="storytelling"
          style={
            isHorizontal
              ? {
                  maxWidth: "100%",
                  paddingRight: "8%",
                  marginLeft: "-20%",
                  paddingBottom: "5%",
                }
              : { maxWidth: "80%" }
          }
        /> */}
      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons" style={{ gridTemplateColumns: "auto auto auto" }}>
        <div></div> <div></div>
        <button className="button-aflr" onClick={saveAndContinue}>
          Next
        </button>
      </div>
    </>
  );
}
