export const useEnvironment = () => {
  const environment =
    process.env.NODE_ENV !== "development" && !isStaging ? "production" : "development";
  const isDev = environment === "development";
  const isProd = environment === "production";
  return { environment, isDev, isProd };
};

export const isStaging = /staging|localhost/.test(window.location.href);
export const studioURL = isStaging
  ? "http://mtnsnd-std-staging.s3-website-eu-west-1.amazonaws.com"
  : "http://mtnsnd-std.s3-website-eu-west-1.amazonaws.com";
