import { useLayoutEffect, useState } from "react";

const horizontal = "horizontal";
const vertical = "vertical";
const intermediate = "intermediate";

export const useOrientation = (widthThresold = 1050) => {
  const [orientation, setOrientation] = useState(
    window.innerWidth < widthThresold ? vertical : horizontal
  );

  useLayoutEffect(() => {
    function reportWindowSize() {
      if (window.innerWidth < widthThresold) {
        setOrientation(vertical);
      } else {
        setOrientation(horizontal);
      }
    }
    window.addEventListener("resize", reportWindowSize, true);
    return () => window.removeEventListener("resize", reportWindowSize, true);
  }, []);

  const isHorizontal = orientation === horizontal;
  const isVertical = orientation === vertical;

  return { orientation, isHorizontal, isVertical };
};

export const useAdvancedOrientation = (
  widthThresoldOne = 650,
  widthThresoldTwo = 1050,
  conditions
) => {
  const [orientation, setOrientation] = useState(
    window.innerWidth < widthThresoldOne
      ? vertical
      : window.innerWidth < widthThresoldTwo
      ? intermediate
      : horizontal
  );

  useLayoutEffect(() => {
    function reportWindowSize() {
      if (window.innerWidth < widthThresoldOne) {
        setOrientation(vertical);
      } else if (window.innerWidth < widthThresoldTwo) {
        setOrientation(intermediate);
      } else {
        setOrientation(horizontal);
      }
    }
    window.addEventListener("resize", reportWindowSize, true);
    return () => window.removeEventListener("resize", reportWindowSize, true);
  }, []);

  const isHorizontal = orientation === horizontal;
  const isVertical = orientation === vertical;
  const isIntermediate = orientation === intermediate;

  const currentCondition = isHorizontal
    ? conditions[0]
    : isIntermediate
    ? conditions[1]
    : conditions[2];

  return { orientation, isHorizontal, isVertical, isIntermediate, currentCondition };
};
