import React, { useLayoutEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Paper from "@material-ui/core/Paper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { useAdvancedOrientation } from "../Hooks";
import { TrendingUpOutlined } from "@material-ui/icons";

String.prototype.replaceBetween = function (start, end, what) {
  return this.substring(0, start) + what + this.substring(end);
};

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "none",
    display: "block",
    width: "530px",
    margin: "auto",
    marginTop: "2px",
    marginBottom: "0%"
  }
}));

const widthThresoldOne = 800;
const widthThresoldTwo = 800;

export default function ChooseTemplate(props) {
  const { values, handleChange } = props;
  const { userName, businessName } = values;
  const classes = useStyles();
  const { currentCondition } = useAdvancedOrientation(widthThresoldOne, widthThresoldTwo, [
    "horizontal",
    "middle",
    "vertical"
  ]);

  // speaker choices
  const templates = [
    {
      id: "0",
      value: "3 tips for...",
      text: `Dear <name1>, Welcome to ${businessName}. My name is ${userName} and I’ve prepared 5 easy tips on ...\nTip number 1: …\nTip number 2: …\nTip number 3: …\nI hope these tips are useful for your quest for success. If you want more support I will be happy to assist you with my personal program. <name>, click the button below within the next minute and you will receive an exclusive 10% discount.`
    },
    {
      id: "1",
      value: "How to...",
      text: `Dear <name1>, Welcome to ${businessName}. My name is ${userName} here is my guide on how to ...\nI hope this guide is useful for your quest for success. If you want more support I will be happy to assist you with my personal program. <name>, click the button below within the next minute and you will receive an exclusive 10% discount.`
    },
    {
      id: "2",
      value: "3 mistakes to avoid in...",
      text: `Dear <name>, Welcome to ${businessName}. My name is ${userName} and here are 3 deadly mistakes to avoid In ...\nMistake number 1: …\nMistake number 2: …\nMistake number 3: …\nI hope these tips are useful for your quest for success. If you want more support I will be happy to assist you with my personal program. <name>, click the button below within the next minute and you will receive an exclusive 10% discount.`
    }
  ];

  const renderHTML = (str, val1, val2) => {
    if (str.split(val1).length - 1 !== str.split(val2).length - 1) return "Problem";
    var indexes = [];
    var indexes2 = [],
      i;
    for (i = 0; i < str.length; i++) if (str[i] === val1) indexes.push(i);
    indexes.sort(function (a, b) {
      return b - a;
    });
    for (i = 0; i < str.length; i++) if (str[i] === val2) indexes2.push(i);
    indexes2.sort(function (a, b) {
      return b - a;
    });
    var j;
    for (j = 0; j < indexes.length; j++) {
      let lessThan = indexes[j];
      let greaterThan = indexes2[j] + 1;
      let tag = str.substring(lessThan, greaterThan);
      str = str.replaceBetween(
        lessThan,
        greaterThan,
        `<b style=color:#00CDC7;>${tag.slice(1, -1)}</b>`
      );
    }
    var indexes3 = [];
    for (i = 0; i < str.length; i++) if (str[i] === "\n") indexes3.push(i);
    indexes3.reverse();
    var k;
    for (k = 0; k < indexes3.length; k++) {
      let lessThan = indexes3[k];
      let greaterThan = indexes3[k] + 1;
      let tag = str.substring(lessThan, greaterThan);
      str = str.replaceBetween(lessThan, greaterThan, `<br />`);
    }
    return str;
  };

  const [editing, setEditing] = useState(TrendingUpOutlined);

  return (
    <>
      {currentCondition === "horizontal" && (
        <ToggleButtonGroup exclusive onChange={handleChange("text")}>
          {templates.map((option) => (
            <ToggleButton
              style={{
                background: "#F2F2F2",
                borderRadius: "4px",
                color: "#414141",
                fontFamily: "Lato",
                boxShadow: "none",
                width: "fit-content",
                border: "1px solid #D4D4D4",
                textTransform: "none",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "bold",
                marginLeft: "25px",
                marginRight: "25px",
                marginBottom: "15px",
                padding: "5px 10px 5px 10px",
                zIndex: "9"
              }}
              key={option.id}
              value={option.text}
            >
              {option.value}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      {currentCondition === "vertical" && (
        <div className="styled-select-template">
          <select value={values.text} onChange={handleChange("text")}>
            {templates.map((option) => (
              <option key={option.id} value={option.text}>
                {option.value}
              </option>
            ))}
          </select>
        </div>
      )}

      {editing ? (
        <div className="input">
          <TextareaAutosize
            className="template"
            type="text"
            value={values.text}
            onChange={handleChange("text")}
          ></TextareaAutosize>
        </div>
      ) : (
        <div className="input">
          <div
            className="template-display"
            dangerouslySetInnerHTML={{
              __html: renderHTML(values.text, "<", ">")
            }}
          />
        </div>
      )}
      <div className="template-button-container">
        <button
          className="button-aflr-info"
          style={{ justifySelf: "end" }}
          disabled={editing}
          color="info"
          onClick={() => {
            setEditing(true);
          }}
        >
          Edit
        </button>
        <button
          className="button-aflr-info"
          disabled={!editing}
          color="info"
          onClick={() => {
            setEditing(false);
          }}
        >
          Preview
        </button>
      </div>
    </>
  );
}
