import React, { useEffect } from "react";
import { useEnvironment } from "components/Hooks";
import "./AflrReusable.css";

export default function (props) {
  const { environment, isDev, isProd } = useEnvironment();
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://aflr-assets.s3-eu-west-1.amazonaws.com/widget/bundle.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        id="aflr_widget"
        data-url={props.audio}
        data-key={props.apiKey}
        data-env={environment}
        style={props.style}
      />
    </>
  );
}
