import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },

  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.common.white,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Lato",
    "&:focus": {
      border: "1px solid #0742B9",
      boxShadow:
        "0px 18.64px 18.64px rgba(50, 50, 71, 0.08), 0px 27.96px 37.28px rgba(50, 50, 71, 0.08)",
    },
    "&:error": {
      boxShadow:
        "0px 18.64px 18.64px rgba(250, 50, 71, 0.08), 0px 27.96px 37.28px rgba(250, 50, 71, 0.08)",
    },
  },
}))(InputBase);

export default function UserInput(props) {
  const classes = props.isError === true ? " error-shadow" : "animation";
  const { values, handleChange, type, description } = props;
  return (
    <>
      <FormControl className="user-info-boxes">
        <InputLabel shrink htmlFor="bootstrap-input">
          {description}
        </InputLabel>
        <BootstrapInput
          className={classes}
          type="text"
          error="true"
          value={values[type]}
          onChange={handleChange(`${type}`)}
          id="bootstrap-input"
        />
      </FormControl>

      <br></br>
      <br></br>
    </>
  );
}
