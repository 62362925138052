import React from "react";

const App = () => {
  return (
    <div className="App">
      <svg
        width="112"
        height="129"
        viewBox="0 0 112 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100.16 126.805H11.3999C11.3999 126.805 15.3499 99.5736 22.1099 94.3677C28.8699 89.1617 45.6699 84.6967 45.6699 84.6967H66.7599C66.5999 84.4264 82.6799 87.7501 89.1799 93.1763C97.1899 99.8539 100.16 126.805 100.16 126.805Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-miterlimit="10"
        />
        <path
          d="M29.3899 112.038V127.725"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M83.3098 112.038V127.725"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M65.4699 91.4044C62.6899 93.4067 59.8699 95.3589 56.2099 95.3589C52.5499 95.3589 49.7299 93.3566 46.9499 91.4044C44.1699 89.4522 42.0599 86.4487 44.9499 85.3074C46.7599 84.5966 46.9499 78.4796 46.8899 73.9044C46.8399 71.0912 46.6599 68.8987 46.6599 68.8987H65.6599C65.4262 72.0934 65.3661 75.2985 65.4799 78.4997C65.6399 81.8235 66.1299 84.8569 67.3699 85.3375C70.3599 86.4487 68.2499 89.4221 65.4699 91.4044Z"
          fill="white"
        />
        <path
          d="M65.3199 80.2517C61.7499 76.4374 49.4999 76.087 47.3199 76.0569C47.3199 73.9545 47.1699 72.1625 47.1199 71.3916H47.3199C49.9816 73.0911 53.0729 73.994 56.2299 73.994C59.3868 73.994 62.4781 73.0911 65.1399 71.3916H65.3398C65.0424 74.3371 65.0357 77.3049 65.3199 80.2517Z"
          fill="#E19696"
        />
        <path
          d="M65.4699 91.4044C62.6899 93.4067 59.8699 95.3589 56.2099 95.3589C52.5499 95.3589 49.7299 93.3566 46.9499 91.4044C44.1699 89.4522 42.0599 86.4487 44.9499 85.3074C46.7599 84.5966 46.9499 78.4796 46.8899 73.9044C46.8399 71.0912 46.6599 68.8987 46.6599 68.8987H65.6599C65.4262 72.0934 65.3661 75.2985 65.4799 78.4997C65.6399 81.8235 66.1299 84.8569 67.3699 85.3375C70.3599 86.4487 68.2499 89.4221 65.4699 91.4044Z"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.1698 37.5831C33.1698 37.5831 28.9199 36.582 27.2898 39.6454C25.8198 42.3986 27.1399 50.3477 34.4799 51.8093C41.8199 53.271 37.5498 39.0748 33.1698 37.5831Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.4699 43.7301C33.4699 43.7301 31.7199 40.8368 29.3899 40.5164"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.8298 41.7878C30.9404 42.4418 30.2484 43.3286 29.8298 44.3508"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.5199 37.5831C79.5199 37.5831 83.7799 36.582 85.4099 39.6454C86.8699 42.3986 85.5599 50.3477 78.2199 51.8093C70.8799 53.271 75.1499 39.0748 79.5199 37.5831Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.23 43.7301C79.23 43.7301 80.97 40.8368 83.31 40.5164"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M80.9099 41.7878C81.7993 42.4418 82.4913 43.3286 82.9099 44.3508"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.1499 37.1227C78.7699 29.2237 76.4999 11.5836 56.2099 11.5836C37.2099 11.5836 33.8899 26.2103 33.2999 37.0626C33.1799 39.225 33.1699 41.4075 33.1699 43.5299C33.1699 52.8705 38.7199 63.7429 46.6999 68.8687C49.4922 70.8006 52.8058 71.8353 56.1999 71.8353C59.594 71.8353 62.9077 70.8006 65.6999 68.8687C73.6999 63.7429 79.2299 52.8505 79.2299 43.5299C79.2499 41.4275 79.2499 39.2651 79.1499 37.1227Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.62 38.5941C48.622 38.9747 48.5111 39.3474 48.3013 39.6648C48.0915 39.9822 47.7924 40.2301 47.4417 40.3772C47.0911 40.5242 46.7047 40.5637 46.3316 40.4906C45.9585 40.4176 45.6155 40.2353 45.3459 39.9669C45.0764 39.6985 44.8926 39.356 44.8177 38.9829C44.7428 38.6097 44.7802 38.2227 44.9252 37.8709C45.0702 37.5191 45.3163 37.2183 45.6323 37.0066C45.9482 36.7949 46.3198 36.682 46.7 36.6819C47.2075 36.6819 47.6943 36.8831 48.0541 37.2414C48.4139 37.5997 48.6174 38.0861 48.62 38.5941Z"
          fill="#2C2A3F"
        />
        <path
          d="M67.4198 38.5941C67.4218 38.9747 67.3109 39.3474 67.1011 39.6648C66.8913 39.9822 66.5922 40.2301 66.2415 40.3772C65.8909 40.5242 65.5045 40.5637 65.1314 40.4906C64.7583 40.4176 64.4153 40.2353 64.1458 39.9669C63.8762 39.6985 63.6924 39.356 63.6175 38.9829C63.5426 38.6097 63.58 38.2227 63.725 37.8709C63.8701 37.5191 64.1161 37.2183 64.4321 37.0066C64.7481 36.7949 65.1197 36.682 65.4998 36.6819C66.0073 36.6819 66.4942 36.8831 66.8539 37.2414C67.2137 37.5997 67.4172 38.0861 67.4198 38.5941Z"
          fill="#2C2A3F"
        />
        <path
          d="M58.7598 33.4583C58.1829 36.6273 58.0148 39.8574 58.2598 43.0692C58.7598 47.5744 59.8698 49.5566 58.7598 49.927C57.5308 50.1221 56.2788 50.1221 55.0498 49.927"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.62 32.5974C48.62 32.5974 43.24 31.2959 41.38 33.4583C41.38 33.4583 40.89 30.6752 44.23 30.6151C47.57 30.5551 48.62 32.5974 48.62 32.5974Z"
          fill="#2C2A3F"
        />
        <path
          d="M63.7898 32.5974C63.7898 32.5974 69.1698 31.2959 71.0198 33.4583C71.0198 33.4583 71.5098 30.6752 68.1798 30.6151C64.8498 30.5551 63.7898 32.5974 63.7898 32.5974Z"
          fill="#2C2A3F"
        />
        <path
          d="M48.6199 55.6235C48.6199 55.6235 57.0299 53.391 62.8398 55.6235C62.0502 56.788 60.9915 57.7447 59.7538 58.4122C58.516 59.0797 57.1357 59.4384 55.7299 59.4579C50.9699 59.4579 48.7399 56.6147 48.6199 55.6235Z"
          fill="#2C2A3F"
        />
        <path
          d="M51.72 58.647C52.1313 57.9991 52.7638 57.5231 53.4998 57.3076C54.2357 57.0921 55.0248 57.1519 55.72 57.4757C56.0695 57.2146 56.4798 57.0471 56.9121 56.9893C57.3444 56.9315 57.7842 56.9852 58.19 57.1453C59.55 57.726 59.82 58.3867 59.82 58.3867C58.5795 59.047 57.2044 59.4139 55.8002 59.459C54.396 59.5041 53.0002 59.2263 51.72 58.647Z"
          fill="#E19696"
        />
        <path
          d="M39.9899 56.2743C41.7999 56.2743 53.1199 50.5778 57.4099 53.5612C58.6939 52.8724 60.1281 52.512 61.5849 52.512C63.0416 52.512 64.4758 52.8724 65.7599 53.5612C70.3299 55.9039 72.3399 56.9951 74.5099 56.2743C77.9999 55.123 79.1499 48.6757 79.4099 38.7644C79.4099 38.7644 83.3499 75.3159 56.4799 75.3159C29.6099 75.3159 33.0298 43.4697 32.9098 40.216C32.7898 36.9623 34.3799 56.2743 39.9899 56.2743Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.46 58.6871C50.46 58.6871 56.76 61.5805 60.97 58.597"
          stroke="white"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.15 33.6187C33.15 33.6187 36.86 26.2003 49.39 24.8888C61.92 23.5773 77.53 25.95 79.25 33.1482C79.8581 32.4604 80.3191 31.6552 80.6046 30.7823C80.8901 29.9093 80.994 28.9871 80.91 28.0724C80.42 18.8819 79.24 1.57224 54.24 1.57224C29.82 1.57224 29.13 28.0223 33.15 33.6187Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M81.37 32.8678C80.59 34.4997 79.04 32.8678 79.04 32.8678C72.61 24.5984 57.14 25.7497 57.14 25.7497C57.14 25.7497 39.59 25.3493 33.14 33.6187C33.14 33.6187 31.59 35.2505 30.81 33.6187C30.03 31.9868 28.94 26.8209 29.81 25.5195C30.68 24.218 38.44 16.3591 57.13 16.3591C75.82 16.3591 81.4901 23.4872 82.3601 24.7786C83.23 26.0701 82.15 31.2259 81.37 32.8678Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.8198 26.1402C30.8198 26.1402 31.1998 31.4462 32.1698 32.5275"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M32.1699 24.9487C32.38 27.1383 32.7139 29.3143 33.1699 31.4661"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.8499 23.5973C33.955 25.8121 34.2562 28.0132 34.7498 30.1747"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M35.3999 22.5761C35.4899 24.719 35.7271 26.8531 36.1099 28.9634"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M37.1599 21.6649C37.1599 21.6649 37.4999 26.6706 37.7199 28.2124"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M38.98 20.8441C39.0251 23.0319 39.229 25.2134 39.59 27.3716"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.76 20.2333C40.8136 22.45 41.014 24.6607 41.36 26.8508"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M42.4099 19.7028C42.4532 21.8872 42.6571 24.0656 43.0199 26.2201"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M44.0698 19.0721C44.1131 21.2566 44.317 23.435 44.6798 25.5895"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M45.73 18.8318C45.7733 21.0164 45.9772 23.1947 46.34 25.3492"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M47.2 18.4014C47.2508 20.5855 47.4547 22.7633 47.8099 24.9188"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.8499 18.1111C48.8932 20.2956 49.097 22.4739 49.4598 24.6284"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M50.6199 17.8705C50.6651 20.0549 50.8689 22.2332 51.2299 24.3879"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M52.45 17.8705C52.4952 20.0549 52.699 22.2332 53.0599 24.3879"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M81.4998 25.3192C81.4998 25.3192 81.2698 30.6352 80.3198 31.7465"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M80.1198 24.1678C79.9721 26.3614 79.6949 28.5443 79.2898 30.7052"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.3999 22.8564C78.3569 25.076 78.1159 27.2872 77.6799 29.4639"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.8298 21.8751C76.7961 24.0207 76.6157 26.1615 76.2898 28.2824"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M75.0499 21.0142C75.0499 21.0142 74.8299 26.0199 74.6599 27.5717"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M73.2098 20.2534C73.2194 22.4369 73.0724 24.6184 72.7698 26.7808"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M71.41 19.6826C71.41 21.8985 71.2697 24.1121 70.99 26.3102"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M69.7498 19.2021C69.767 21.3859 69.6199 23.5679 69.3098 25.7296"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.0699 18.6116C68.0873 20.7987 67.9402 22.9841 67.6299 25.149"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M66.41 18.4212C66.412 20.6045 66.265 22.7854 65.97 24.9486"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M64.91 18.0209C64.9274 20.208 64.7803 22.3933 64.47 24.5583"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M63.2698 17.7606C63.2803 19.9441 63.1332 22.1256 62.8298 24.288"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.4898 17.5804C61.5072 19.7675 61.3601 21.9529 61.0498 24.1179"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59.6599 17.6305C59.4799 23.6874 59.4799 23.2369 59.4399 24.168"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M54.48 17.5905C54.48 17.5905 54.56 22.8064 54.76 24.288"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M58.01 17.5905C58.01 17.5905 57.93 22.8064 57.72 24.288"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.0999 17.5804L56.1199 24.1078"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 127.726H110.46"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default App;
