import React from "react";
import Success from "./Success";
import { useOrientation } from "../Hooks";
import Card from "components/Card/Card";

const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.get("email") || "";
const widthThresold = 600;

export default function () {
  const { orientation, isHorizontal } = useOrientation(widthThresold);
  return (
    <div className="step">
      <Success email={email} />
      <br />
    </div>
  );
}
