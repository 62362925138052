import React from "react";

const verticals = [
  {
    value: "vertical1",
    label: "Coach"
  },
  {
    value: "vertical2",
    label: "Consultant"
  },
  {
    value: "vertical3",
    label: "Entrepreneur"
  },
  {
    value: "vertical4",
    label: "Freelancer"
  },
  {
    value: "vertical5",
    label: "Other"
  }
];

export default function ChooseTemplate(props) {
  const { values, handleChange } = props;

  return (
    <>
      <div className="dropdown-labels">I work as a</div>
      <div className="styled-select">
        <select
          className="user-info-inputs"
          value={values.userVertical}
          onChange={handleChange("userVertical")}
        >
          {verticals.map((option) => (
            <option key={option.value} value={option.label}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <br></br>
    </>
  );
}

// import React, { useLayoutEffect, useState } from "react";
// import { makeStyles, withStyles } from "@material-ui/core/styles";
// import ToggleButton from "@material-ui/lab/ToggleButton";
// import AflrCard from "../AflrReusableComponents/AflrCard";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import Conrad from "../../assets/img/voices/conrad.png";

// const StyledToggleButtonGroup = withStyles((theme) => ({
//   grouped: {
//     margin: theme.spacing(1),
//     border: "none",

//     "&:not(:first-child)": {
//       borderRadius: theme.shape.borderRadius,
//     },
//     "&:first-child": {
//       borderRadius: theme.shape.borderRadius,
//     },
//   },
// }))(ToggleButtonGroup);

// const verticals = [
//   {
//     value: "vertical1",
//     label: "Coach",
//     imgSrc: Conrad,
//   },
//   {
//     value: "vertical2",
//     label: "Consultant",
//     imgSrc: Conrad,
//   },
//   {
//     value: "vertical3",
//     label: "Entrepreneur",
//     imgSrc: Conrad,
//   },
//   {
//     value: "vertical4",
//     label: "Freelancer",
//     imgSrc: Conrad,
//   },
//   {
//     value: "vertical5",
//     label: "Other",
//     imgSrc: Conrad,
//   },
// ];

// const widthThresold = 900;

// export default function ChooseTemplate(props) {
//   const { values, handleChange } = props;
//   const [orientation, setOrientation] = useState(
//     window.innerWidth < widthThresold ? "vertical" : "horizontal"
//   );

//   useLayoutEffect(() => {
//     function reportWindowSize() {
//       if (window.innerWidth < widthThresold) {
//         setOrientation("vertical");
//       } else {
//         setOrientation("horizontal");
//       }
//     }
//     window.onresize = reportWindowSize;
//   }, []);

//   return (
//     <>
//       <div className="user-info-inputs-with-cards">
//         <div style={{ textAlign: "middle", borderWidth: 1 }}>I work as a</div>
//         <div>
//           <StyledToggleButtonGroup
//             exclusive
//             onChange={handleChange("userVertical")}
//             value={values.userVertical}
//             style={
//               orientation === "horizontal"
//                 ? {
//                     display: "grid",
//                     gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
//                   }
//                 : {
//                     display: "grid",
//                     gridTemplateColumns: "1fr 1fr",
//                   }
//             }
//           >
//             {verticals.map((card) => (
//               <ToggleButton key={card.value} value={card.value}>
//                 <div
//                   style={{
//                     color: "#000",
//                     textTransform: "none",
//                     boxShadow: "0 0px 8px 0 #ccc",
//                     borderRadius: "5px",
//                     padding: "5px",
//                   }}
//                 >
//                   {card.label}
//                 </div>
//               </ToggleButton>
//             ))}
//           </StyledToggleButtonGroup>
//         </div>
//       </div>
//     </>
//   );
// }

/*
<StyledToggleButtonGroup
          exclusive
          orientation={orientation}
          onChange={handleChange("speaker")}
          value={values.speaker}
          style={
            orientation === "horizontal"
              ? {
                  display: "grid",
                  gridTemplateColumns: "25% 25% 25% 25% ",
                }
              : undefined
          }
        >
          {cards.map((card) => (
            <ToggleButton key={card.speakerName} value={card.title}>
              <AflrCard
                title={card.title}
                description={card.description}
                imgAlt={card.speakerName}
                imgSrc={card.imgSrc}
                audio={
                  s3bucket1 +
                  card.speakerName +
                  s3bucket2 +
                  uName.toLowerCase() +
                  s3bucket3
                }
              />
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
*/
