import React from "react";

const App = () => {
  return (
    <div className="App">
      <svg
        width="112"
        height="128"
        viewBox="0 0 112 128"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M85.0101 34.4703V70.5914C85.0101 70.5914 84.2001 76.5281 75.0101 78.6005C65.8201 80.6728 59.7201 82.2246 54.5501 82.2246C49.3801 82.2246 43.6501 80.6928 34.0901 78.6005C24.8401 76.5982 24.0901 70.5914 24.0901 70.5914V34.4703C24.0901 10.8034 40.0901 1.05233 54.5601 1.05233C69.0301 1.05233 85.0101 10.8435 85.0101 34.4703Z"
          fill="#E19696"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M98.47 126.165H9.70996C9.70996 126.165 13.66 98.9236 20.42 93.7277C27.18 88.5318 43.98 84.0567 43.98 84.0567H65.07C64.91 83.7864 80.99 87.0601 87.49 92.5263C95.51 99.2139 98.47 126.165 98.47 126.165Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-miterlimit="10"
        />
        <path
          d="M27.7 111.388V126.164"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M81.6201 111.388V126.164"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M76.9101 92.1159C71.2201 103.759 61.1101 105.952 54.5301 105.952C47.9501 105.952 37.8301 103.729 32.1501 92.0858C31.8698 91.4697 31.7249 90.8006 31.7249 90.1236C31.7249 89.4466 31.8698 88.7775 32.1501 88.1614L37.8401 86.2292C37.5478 86.7214 37.3935 87.2834 37.3935 87.8561C37.3935 88.4287 37.5478 88.9907 37.8401 89.4829C38.8401 91.4852 45.4801 98.6433 54.5101 98.6433C63.5401 98.6433 70.2101 91.4652 71.1801 89.4829C71.463 88.8999 71.6166 88.2625 71.6304 87.6145C71.6442 86.9665 71.5179 86.3232 71.2601 85.7286L76.8801 87.4606C77.89 89.3928 76.9101 92.1159 76.9101 92.1159Z"
          fill="white"
        />
        <path
          d="M63.7799 90.7544C60.9999 92.7567 58.1799 94.7589 54.5199 94.7589C50.8599 94.7589 48.0399 92.7567 45.2599 90.7544C42.4799 88.7521 40.3799 85.7988 43.2599 84.6575C45.0699 83.9567 45.2599 77.8297 45.1999 73.2645C45.1999 70.4513 44.9699 68.2588 44.9699 68.2588H63.9699C63.7359 71.4535 63.6758 74.6585 63.7899 77.8597C63.9499 81.1835 64.4499 84.207 65.6899 84.6875C68.6699 85.7988 66.5699 88.7721 63.7799 90.7544Z"
          fill="white"
        />
        <path
          d="M63.4602 77.4591C59.8902 73.6448 47.8102 73.2944 45.6102 73.2644C45.6102 71.162 45.4602 69.3699 45.4102 68.6091H45.6102C48.274 70.3028 51.3645 71.2022 54.5202 71.2022C57.6758 71.2022 60.7663 70.3028 63.4302 68.6091H63.6302C63.5502 69.8405 63.2902 73.805 63.4602 77.4591Z"
          fill="#E19696"
        />
        <path
          d="M63.7799 90.7544C60.9999 92.7567 58.1799 94.7589 54.5199 94.7589C50.8599 94.7589 48.0399 92.7567 45.2599 90.7544C42.4799 88.7521 40.3799 85.7988 43.2599 84.6575C45.0699 83.9567 45.2599 77.8297 45.1999 73.2645C45.1999 70.4513 44.9699 68.2588 44.9699 68.2588H63.9699C63.7359 71.4535 63.6758 74.6585 63.7899 77.8597C63.9499 81.1835 64.4499 84.207 65.6899 84.6875C68.6699 85.7988 66.5699 88.7721 63.7799 90.7544Z"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.4802 36.9432C31.4802 36.9432 27.2302 35.9421 25.6002 39.0055C24.1302 41.7487 25.4502 49.6977 32.7902 51.1593C40.1302 52.621 35.8602 38.4249 31.4802 36.9432Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.7799 43.0901C31.7799 43.0901 30.0399 40.1968 27.7 39.8765"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M30.1401 41.1379C29.2493 41.7946 28.5571 42.685 28.1401 43.7108"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.8401 36.9432C77.8401 36.9432 82.0901 35.9421 83.7201 39.0055C85.1901 41.7487 83.8701 49.6977 76.5301 51.1593C69.1901 52.621 73.4601 38.4249 77.8401 36.9432Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.54 43.0901C77.54 43.0901 79.29 40.1968 81.6201 39.8765"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M79.1799 41.1379C80.0665 41.799 80.7576 42.6881 81.1799 43.7108"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M66.9601 17.2807C66.9601 17.2807 62.2201 21.2853 54.5201 18.9426C46.8201 16.6 38.5201 24.3988 37.5201 29.9551C37.2506 31.477 36.5549 32.891 35.5139 34.0324C34.473 35.1738 33.1296 35.9957 31.6401 36.4025C32.2401 25.5601 35.6401 15.1383 54.5501 15.1383C58.7889 15.0059 63.0105 15.7347 66.9601 17.2807Z"
          fill="#EEC0C0"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.46 36.4725C73.84 36.1622 76.8 30.686 72.56 27.8628C68.32 25.0395 66.98 17.4009 66.96 17.2807C75.29 20.8548 77.08 28.5736 77.46 36.4725Z"
          fill="#EEC0C0"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M82.8401 34.4602C82.8401 34.4602 81.3401 36.7128 77.5701 36.4625H77.4601C73.8401 36.1521 76.8001 30.6759 72.5601 27.8527C68.3201 25.0295 66.9801 17.3908 66.9601 17.2707C66.9401 17.1505 62.2201 21.2752 54.5201 18.9326C46.8201 16.5899 38.5201 24.3888 37.5201 29.9451C37.2506 31.467 36.5549 32.8809 35.5139 34.0223C34.473 35.1637 33.1296 35.9857 31.6401 36.3924C31.3601 36.4825 30.6401 35.7417 30.6401 35.7417L31.4801 12.8457L58.1401 8.23041L75.0801 12.5153L82.8401 34.4602Z"
          fill="#E19696"
        />
        <path
          d="M77.57 42.8798C77.57 52.2305 72.01 63.1028 64.03 68.2286C61.2351 70.1538 57.9225 71.1845 54.53 71.1845C51.1375 71.1845 47.8249 70.1538 45.03 68.2286C37.03 63.0928 31.5 52.2104 31.5 42.8798C31.5 40.7574 31.5 38.5749 31.63 36.4125C33.1157 36.0025 34.4549 35.1791 35.4921 34.0379C36.5293 32.8968 37.2221 31.4846 37.49 29.9652C38.49 24.3988 46.82 16.64 54.49 18.9526C62.16 21.2653 66.93 17.2908 66.93 17.2908C66.93 17.2908 68.29 25.0296 72.56 27.8628C76.83 30.696 73.84 36.1622 77.46 36.4726C77.57 38.615 77.57 40.7774 77.57 42.8798Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.9301 37.9542C46.9301 38.3344 46.8175 38.706 46.6065 39.0221C46.3955 39.3382 46.0957 39.5846 45.7448 39.7301C45.394 39.8756 45.008 39.9136 44.6355 39.8395C44.2631 39.7653 43.921 39.5822 43.6524 39.3134C43.3839 39.0446 43.2011 38.7021 43.127 38.3292C43.0529 37.9564 43.0909 37.5699 43.2362 37.2186C43.3816 36.8674 43.6277 36.5672 43.9434 36.356C44.2591 36.1448 44.6304 36.032 45.0101 36.032C45.2626 36.0307 45.5129 36.0795 45.7464 36.1757C45.9799 36.2718 46.1921 36.4133 46.3707 36.5921C46.5492 36.7708 46.6906 36.9833 46.7866 37.2171C46.8827 37.4509 46.9314 37.7014 46.9301 37.9542Z"
          fill="#2C2A3F"
        />
        <path
          d="M65.7301 37.9542C65.7301 38.3344 65.6175 38.706 65.4066 39.0221C65.1956 39.3382 64.8957 39.5846 64.5449 39.7301C64.1941 39.8756 63.808 39.9136 63.4356 39.8395C63.0631 39.7653 62.721 39.5822 62.4525 39.3134C62.184 39.0446 62.0011 38.7021 61.927 38.3292C61.8529 37.9564 61.891 37.5699 62.0363 37.2186C62.1816 36.8674 62.4277 36.5672 62.7434 36.356C63.0592 36.1448 63.4304 36.032 63.8101 36.032C64.0626 36.0307 64.3129 36.0795 64.5465 36.1757C64.78 36.2718 64.9922 36.4133 65.1707 36.5921C65.3493 36.7708 65.4907 36.9833 65.5867 37.2171C65.6827 37.4509 65.7315 37.7014 65.7301 37.9542Z"
          fill="#2C2A3F"
        />
        <path
          d="M57.0701 32.8084C56.4939 35.9775 56.3293 39.2079 56.5801 42.4193C57.0701 46.9244 58.1801 48.9067 57.0701 49.2771C55.8411 49.4721 54.5891 49.4721 53.3601 49.2771"
          stroke="#2C2A3F"
          stroke-width="1.92"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M46.9301 31.9474C46.9301 31.9474 41.5501 30.6459 39.7001 32.8084C39.7001 32.8084 39.2001 30.0252 42.5401 29.9651C45.8801 29.9051 46.9301 31.9474 46.9301 31.9474Z"
          fill="#2C2A3F"
        />
        <path
          d="M62.1001 31.9474C62.1001 31.9474 67.4801 30.6459 69.3301 32.8084C69.3301 32.8084 69.8301 30.0252 66.4901 29.9651C63.1501 29.9051 62.1001 31.9474 62.1001 31.9474Z"
          fill="#2C2A3F"
        />
        <path
          d="M46.9299 54.9736C46.9299 54.9736 55.3399 52.7411 61.1499 54.9736C60.3602 56.1381 59.3016 57.0947 58.0638 57.7622C56.8261 58.4298 55.4457 58.7885 54.0399 58.808C49.2899 58.808 47.0499 55.9647 46.9299 54.9736Z"
          fill="#2C2A3F"
        />
        <path
          d="M50.04 58.0071C50.4483 57.3559 51.0809 56.8771 51.8179 56.6612C52.555 56.4454 53.3456 56.5075 54.04 56.8357C54.3883 56.5719 54.7987 56.4029 55.2316 56.345C55.6644 56.2871 56.1048 56.3423 56.51 56.5053C57.88 57.076 58.15 57.7367 58.15 57.7367C56.9084 58.3981 55.532 58.7663 54.1263 58.8131C52.7207 58.86 51.3229 58.5842 50.04 58.0071Z"
          fill="#E19696"
        />
        <path
          d="M45.0101 45.2926C45.0101 46.514 43.0101 47.5051 40.4501 47.5051C37.8901 47.5051 35.8801 46.504 35.8801 45.2926C35.8801 44.0812 37.8801 43.0901 40.4501 43.0901C43.0201 43.0901 45.0101 44.0812 45.0101 45.2926Z"
          fill="#E19696"
        />
        <path
          d="M72.9501 45.2926C72.9501 46.514 70.9501 47.5051 68.3801 47.5051C65.8101 47.5051 63.8101 46.504 63.8101 45.2926C63.8101 44.0812 65.8101 43.0901 68.3801 43.0901C70.9501 43.0901 72.9501 44.0812 72.9501 45.2926Z"
          fill="#E19696"
        />
        <path
          d="M1 126.164H110.46"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default App;
