import React, { useState } from "react";
import PaymentCard from "components/AflrReusableComponents/PaymentCard";

const yearlyPayment = [
  {
    id: "1",
    apiKey: "price_1HP9snAlGWENUchl6cjB1Xlt",
    title: "Early Access Program",
    priceMonth: "0",
    paymentType: "Free forever",
    nSpeakers: "4 Speakers",
    nAudioStories: "Up to 1 Audio Stories",
    nMonthlyplays: "200 Plays Per Month",
    analytics: "Weekly Analytics Report",
    branding: "Audiofunnel Branding",
    advertising: "Audiofunnel Advertising",
    customerSupport: "Email Support (1-2 days)",
    zero: "true"
  },
  {
    id: "2",
    apiKey: "price_1HP3cqAlGWENUchli2CEsUDt",
    title: "Premium",
    priceMonth: "89",
    paymentType: "Per Month  /  Paid Yearly",
    nSpeakers: "20 Speakers",
    nAudioStories: "Up to 10 Audio Stories",
    nMonthlyplays: "1,000 Plays Per Month",
    analytics: "Analytics Dashboard",
    branding: "No Branding",
    advertising: "No Ads",
    customerSupport: "Email Support (1 day)"
  },
  {
    id: "3",
    apiKey: "price_1HP3crAlGWENUchlnAyFPypT",
    title: "Custom",
    priceMonth: "149",
    paymentType: "Per Month  /  Paid Yearly",
    nSpeakers: "All Speakers & Your Voice",
    nAudioStories: "Unlimited Audio Stories",
    nMonthlyplays: "Recommended from 5,000+",
    analytics: "Analytics Dashboard",
    branding: "Your branding",
    advertising: "No Ads",
    customerSupport: "Phone Support (1 day)"
  }
];

const monthlyPayment = [
  {
    id: "1",
    apiKey: "price_1HP3crAlGWENUchlnYlx55Sh",
    title: "Early Access Program",
    priceMonth: "0",
    paymentType: "Free forever",
    nSpeakers: "4 Speakers",
    nAudioStories: "Up to 1 Audio Stories",
    nMonthlyplays: "200 Plays Per Month",
    analytics: "Weekly Analytics Report",
    branding: "Audiofunnel Branding",
    advertising: "Audiofunnel Advertising",
    customerSupport: "Email Support (1-2 days)",
    zero: "true"
  },
  {
    id: "2",
    apiKey: "price_1HPUCRAlGWENUchl9Nzf5ib1",
    title: "Premium",
    priceMonth: "107",
    paymentType: "/month",
    nSpeakers: "20 Speakers",
    nAudioStories: "Up to 10 Audio Stories",
    nMonthlyplays: "1,000 Plays Per Month",
    analytics: "Analytics Dashboard",
    branding: "No Branding",
    advertising: "No Ads",
    customerSupport: "Email Support (1 day)"
  },
  {
    id: "3",
    apiKey: "price_1HPUCaAlGWENUchl3zJnBkz1",
    title: "Custom",
    priceMonth: "179",
    paymentType: "Per Month  /  Paid Monthly",
    nSpeakers: "All Speakers & Your Voice",
    nAudioStories: "Unlimited Audio Stories",
    nMonthlyplays: "Recommended from 5,000+",
    analytics: "Analytics Dashboard",
    branding: "Your branding",
    advertising: "No Ads",
    customerSupport: "Phone Support (1 day)"
  }
];

export default function ChoosePricing(props) {
  const [monthly, setMonthly] = useState(true);
  const { setSelected } = props;
  return (
    <>
      <div className="stripe-title-container">
        <div></div>
        <div className="stripe-title">Please, select your payment option</div>
        <div className="stripe-subtitle">Pay yearly</div>
        <label className="switch">
          <input type="checkbox" onChange={() => setMonthly((checked) => !checked)}></input>
          <span className="slider round"></span>
        </label>
      </div>
      {monthly && (
        <div className="payment-options-container">
          {monthlyPayment.map((payment) => (
            <PaymentCard values={payment} setSelected={setSelected} userValues={props.values} />
          ))}
        </div>
      )}

      {!monthly && (
        <div className="payment-options-container">
          {yearlyPayment.map((payment) => (
            <PaymentCard values={payment} setSelected={setSelected} userValues={props.values} />
          ))}
        </div>
      )}
    </>
  );
}
