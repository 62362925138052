import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import Button from "../CustomButtons/Button";
import Close from "@material-ui/icons/Close";
import EmailValidation from "./EmailValidation";
import { useEnvironment } from "../Hooks";

const Modal = ({ handleClose, show, setShow, checked, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        {children}
        <button
          disabled={!checked}
          className="button-aflr"
          onClick={handleClose}
          style={{ width: "140px", marginBottom: "20px" }}
        >
          Get my audio
        </button>
        <div className="close-button">
          <Button justIcon round color="transparent" onClick={() => setShow(false)}>
            <Close style={{ color: "#D4D4D4", width: "40px", height: "40px" }} />
          </Button>
        </div>
      </section>
    </div>
  );
};

export const prettyEmail = (values) => {
  const {
    userName,
    businessName,
    email,
    speaker,
    music,
    text,
    userVertical,
    userGoal,
    platform,
    step
  } = values;
  const title =
    "Hello! A new email has been collected in Audiofunnel Onboarding\n\nHere are the details of the user:\n\n\n";
  const userStr = "Name: " + userName + "\n";
  const businessStr = "Business name: " + businessName + "\n";
  const verticalStr = "User vertical: " + userVertical + "\n";
  const emailStr = "Email: " + email + "\n";
  const speakerStr = "Speaker: " + speaker + "\n";
  const musicStr = "Music: " + music + "\n";
  const textStr = "Script: " + text + "\n";
  const goalStr = "Goal: " + userGoal + "\n";
  const platformStr = "Platform: " + platform + "\n";
  const stepStr = "\nThis information has been collected in step " + step;
  const str =
    title +
    emailStr +
    userStr +
    businessStr +
    verticalStr +
    goalStr +
    speakerStr +
    musicStr +
    textStr +
    platformStr +
    stepStr;
  return str;
};

export const sendToSlackWebhook = (values, isProd) => {
  const requestOptions = {
    method: "POST",
    headers: { Accept: "application/json" },
    body: JSON.stringify({
      text: prettyEmail(values).toString()
    })
  };
  if (
    isProd &&
    values.businessName.toLowerCase() !== "aflr" &&
    values.businessName.toLowerCase() !== "aflorithmic"
  ) {
    fetch(
      "https://hooks.slack.com/services/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",
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  } else {
    console.log(requestOptions.body);
  }
};

const sendToMailChimp = (userValues) => {
  fetch("https://utils.aflr.io/onboarding_users_to_mailchimp", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email_address: userValues.email,
      status: "subscribed",
      merge_fields: { APIKEY: userValues.userId, FNAME: userValues.userName }
    })
  });
};

export default function EmailPopup(props) {
  const {
    values,
    handleChange,
    nextStep,
    buttonText,
    description,
    beforeOpen,
    buttonWidth
  } = props;
  const [show, setShow] = useState(false);
  const [error, setError] = useState(true);
  const [checked, setChecked] = useState(true);
  const [shouldCheck, setShouldCheck] = useState(false);
  const { isProd } = useEnvironment();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <Modal
        show={show}
        checked={checked}
        setShow={setShow}
        handleClose={() => {
          setShouldCheck(true);
          if (!error) {
            setShow(false);
            nextStep && nextStep();
            sendToSlackWebhook(values, isProd);
            isProd &&
              values.businessName.toLowerCase() !== "aflr" &&
              values.businessName.toLowerCase() !== "aflorithmic" &&
              sendToMailChimp(values);
          }
        }}
      >
        <div className="email-text" style={{ paddingTop: "2%" }}>
          {description}
        </div>
        <div className="introduce-email-text">Please introduce your email</div>
        <EmailValidation
          shouldCheck={shouldCheck}
          setError={setError}
          setShouldCheck={setShouldCheck}
          email={values.email}
          handleChange={handleChange}
        />
        <div className="terms-conditions-grid">
          <input
            className="terms-conditions-check"
            type="checkbox"
            checked={checked}
            onChange={() => setChecked((checked) => !checked)}
          />

          <div className="terms-conditions">
            I agree to the&nbsp;&nbsp;
            <a
              style={{ color: "#414141", textDecoration: "underline" }}
              href="https://audiofunnel.co/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <br></br>
        </div>
      </Modal>
      <button
        disabled={beforeOpen}
        className="button-aflr"
        style={buttonWidth ? { width: 120 } : undefined}
        onClick={() => {
          setShow(true);
        }}
      >
        {buttonText ? buttonText : "NEXT"}
      </button>
    </>
  );
}
