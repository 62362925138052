import React from "react";

const App = () => {
  return (
    <div className="App">
      <svg
        width="112"
        height="129"
        viewBox="0 0 112 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M99.8298 127.569H11.0698C11.0698 127.569 11.4298 125.056 12.1298 121.412C12.4098 119.95 12.7498 118.299 13.1298 116.557C13.5998 114.424 14.1298 112.152 14.7698 109.909C15.4098 107.667 16.0298 105.634 16.7698 103.672C17.504 101.579 18.4489 99.5666 19.5898 97.6652C20.1704 96.6905 20.9147 95.8235 21.7898 95.1022C22.5048 94.5559 23.2529 94.0543 24.0298 93.6005C28.4107 91.2097 33.0121 89.2484 37.7698 87.7439C42.0398 86.3123 45.3498 85.4313 45.3498 85.4313H66.4398C69.4185 85.8811 72.3528 86.5878 75.2098 87.5437C79.7398 88.9352 84.9998 90.9575 88.2998 93.4504L88.8598 93.9009C90.1394 95.0426 91.2119 96.3974 92.0298 97.9054C93.0908 99.8298 93.9905 101.839 94.7198 103.912C95.4098 105.854 96.0298 107.917 96.5698 109.969C97.1598 112.222 97.6698 114.474 98.0898 116.577C98.4498 118.389 98.7598 120.091 98.9998 121.582C99.5698 125.186 99.8298 127.569 99.8298 127.569Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M29.0601 112.792V127.569"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M82.98 112.792V127.569"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M88.29 93.4803C77.2125 95.4861 65.9773 96.4913 54.72 96.4837C44.4171 96.5637 34.1322 95.6078 24.02 93.6305C28.4009 91.2397 33.0022 89.2783 37.76 87.7738C50.2324 88.4729 62.7358 88.406 75.2 87.5736C79.73 88.9652 84.99 90.9875 88.29 93.4803Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M94.71 103.992C81.5775 106.911 68.162 108.361 54.71 108.317C34.15 108.317 20.99 104.953 16.71 103.672C17.4441 101.579 18.389 99.5665 19.5299 97.6651C27.0799 98.9166 52.3999 105.344 91.9699 97.9254C93.0511 99.8675 93.9676 101.897 94.71 103.992Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M98.0799 116.657C83.6148 119.003 68.9836 120.171 54.3299 120.151C34.7499 120.151 20.7199 118.068 13.1299 116.557C13.5999 114.424 14.1299 112.152 14.7699 109.909C26.3199 111.271 51.3899 118.248 96.5599 110.049C97.1699 112.302 97.6599 114.554 98.0799 116.657Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M99.8298 127.569H11.0698C11.0698 127.569 11.4298 125.056 12.1298 121.412C21.5898 122.684 52.1998 125.837 98.9898 121.672C99.5698 125.186 99.8298 127.569 99.8298 127.569Z"
          fill="#2C2A3F"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M46.3698 71.3652C46.3698 71.3652 41.3698 72.5866 41.0298 68.562C41.0298 68.562 34.9598 68.7122 35.2998 62.6152C35.2998 62.6152 25.7398 61.9545 27.7098 53.7051C27.7098 53.7051 18.9998 52.704 23.1798 41.271C23.1798 41.271 17.3298 36.7659 19.4698 27.185C20.2898 21.5786 25.6498 18.6853 25.6498 18.6853C25.6498 18.6853 25.4098 11.7775 29.4898 9.67508C34.5998 6.99203 38.1198 7.20227 38.1198 7.20227C38.1198 7.20227 40.9098 2.13652 46.3698 1.25552C48.5746 0.89955 50.8293 1.0096 52.9891 1.5786C55.1488 2.14759 57.1656 3.16293 58.9098 4.55927C61.1011 3.07449 63.6542 2.2138 66.2962 2.0692C68.9383 1.92461 71.5699 2.50154 73.9098 3.73834C78.9098 6.37133 79.7498 12.258 79.7498 12.258C79.7498 12.258 85.5998 11.8275 87.7498 16.603C90.7498 23.3807 87.7498 25.6933 87.7498 25.6933C87.7498 25.6933 95.1698 29.0871 93.6698 36.2753C92.9265 40.3794 90.7329 44.0787 87.4898 46.6972C87.4898 46.6972 91.3498 55.5873 81.5398 57.7998C81.5398 57.7998 81.5398 62.2849 76.3598 63.0257C76.3598 63.0257 76.5198 70.0337 67.0898 70.0337C67.0898 70.0036 57.4498 80.1151 46.3698 71.3652Z"
          fill="#E9C179"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M65.1701 92.1589C62.3901 94.1612 59.5701 96.1635 55.9101 96.1635C52.2501 96.1635 49.4301 94.1612 46.6501 92.1589C43.8701 90.1566 41.7701 87.2033 44.6501 86.062C46.4601 85.3612 46.6501 79.2342 46.5901 74.669C46.5401 71.8558 46.3601 69.6633 46.3601 69.6633H65.3601C65.1261 72.858 65.066 76.0631 65.1801 79.2643C65.3401 82.588 65.8301 85.6115 67.0701 86.092C70.0301 87.2033 67.9301 90.1767 65.1701 92.1589Z"
          fill="white"
        />
        <path
          d="M65.36 70.0036C62.5048 71.7008 59.2456 72.5965 55.925 72.5965C52.6044 72.5965 49.3452 71.7008 46.49 70.0036C46.49 70.7444 46.65 72.5365 46.69 74.6689C49.32 74.7189 61.4 75.1194 65.28 78.7235C65.05 75.0593 65.28 71.1749 65.36 70.0036Z"
          fill="#E19696"
        />
        <path
          d="M65.1701 92.1589C62.3901 94.1612 59.5701 96.1635 55.9101 96.1635C52.2501 96.1635 49.4301 94.1612 46.6501 92.1589C43.8701 90.1566 41.7701 87.2033 44.6501 86.062C46.4601 85.3612 46.6501 79.2342 46.5901 74.669C46.5401 71.8558 46.3601 69.6633 46.3601 69.6633H65.3601C65.1261 72.858 65.066 76.0631 65.1801 79.2643C65.3401 82.588 65.8301 85.6115 67.0701 86.092C70.0301 87.2033 67.9301 90.1767 65.1701 92.1589Z"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M34.17 52.5638C26.83 51.1021 25.51 43.1531 26.98 40.41C27.4025 39.6979 28.0117 39.1152 28.7415 38.7252C29.4714 38.3351 30.294 38.1526 31.12 38.1975C31.9406 40.5458 32.5235 42.9707 32.86 45.4357C32.86 45.4357 32.86 45.3456 32.86 45.1854C32.92 44.3745 33.14 41.7615 33.58 38.7281C37.67 41.3811 41.08 53.9454 34.17 52.5638Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.1698 44.4946C33.1698 44.4946 31.4198 41.6013 29.0898 41.271"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M31.5 42.5424C30.6092 43.1991 29.917 44.0895 29.5 45.1154"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M77.89 52.5639C71.19 53.9054 74.16 42.1921 78.06 38.9985C78.4 40.9107 78.68 43.003 78.9 45.4258L79.7299 38.2577C80.9299 38.1075 83.8099 38.0174 85.0799 40.4101C86.5499 43.1532 85.2299 51.1022 77.89 52.5639Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.8999 44.4946C78.8999 44.4946 80.6399 41.6013 82.9799 41.271"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M80.54 42.5424C81.4309 43.1991 82.123 44.0895 82.54 45.1154"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M78.8999 45.4257C78.4699 54.496 73.0499 64.6976 65.3899 69.6232C62.595 71.5483 59.2824 72.579 55.8899 72.579C52.4974 72.579 49.1848 71.5483 46.3899 69.6232C38.6699 64.6175 33.2199 54.3057 32.8799 45.1754C33.0499 42.9829 34.3299 29.5076 38.1399 28.156C42.2399 26.6744 46.3899 30.2985 51.4099 26.1538C54.4999 23.6209 57.2799 21.8589 58.9299 23.3506C60.5799 24.8423 61.9299 26.4241 65.1899 25.8034C71.3499 24.632 76.9799 24.2616 78.8999 45.4257Z"
          fill="white"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.2899 39.3587C48.255 39.8432 48.038 40.2965 47.6827 40.6274C47.3274 40.9583 46.8602 41.1423 46.3749 41.1423C45.8897 41.1423 45.4224 40.9583 45.0672 40.6274C44.7119 40.2965 44.495 39.8432 44.46 39.3587C44.46 38.2975 45.31 38.1774 46.37 38.1774C47.43 38.1774 48.2899 38.2775 48.2899 39.3587Z"
          fill="#2C2A3F"
        />
        <path
          d="M67.09 39.3587C67.09 39.8685 66.8877 40.3574 66.5276 40.7179C66.1676 41.0784 65.6792 41.2809 65.17 41.2809C64.6608 41.2809 64.1724 41.0784 63.8124 40.7179C63.4523 40.3574 63.25 39.8685 63.25 39.3587C63.25 38.2975 64.11 38.1774 65.17 38.1774C66.23 38.1774 67.09 38.2775 67.09 39.3587Z"
          fill="#2C2A3F"
        />
        <path
          d="M58.43 34.2129C57.8538 37.382 57.6891 40.6124 57.94 43.8238C58.43 48.3289 59.54 50.3112 58.43 50.6816C57.201 50.8766 55.949 50.8766 54.72 50.6816"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M48.29 33.3519C48.29 33.3519 42.91 32.0504 41.06 34.2129C41.06 34.2129 40.56 31.4297 43.9 31.3697C47.24 31.3096 48.29 33.3519 48.29 33.3519Z"
          fill="#2C2A3F"
        />
        <path
          d="M63.46 33.3519C63.46 33.3519 68.8399 32.0504 70.6899 34.2129C70.6899 34.2129 71.1899 31.4297 67.8499 31.3697C64.5099 31.3096 63.46 33.3519 63.46 33.3519Z"
          fill="#2C2A3F"
        />
        <path
          d="M61.3499 57.0088C61.3499 57.0088 53.9299 60.0122 51.6199 57.0088"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 127.569H110.46"
          stroke="#2C2A3F"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default App;
