import React, { useState } from "react";
import Button from "../CustomButtons/Button";
import "./steps.css";
import ChooseSpeaker from "components/AflrUserInputComponents/ChooseSpeaker";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

export default function Step2(props) {
  const [classes, setClasses] = useState("error-hidden");
  const saveAndContinue = (e) => {
    if (props.values.speaker !== "" && props.values.speaker !== undefined) {
      e.preventDefault();
      if (!props.values.userId) {
        e.preventDefault();
      }
      props.nextStep();
    } else {
      setClasses("error-shown");

      setTimeout(
        function () {
          setClasses("error-hidden");
        }.bind(this),
        3000
      );
    }
  };
  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };
  const { values } = props;
  const { userName, businessName } = values;

  return (
    <div>
      <TitleAndInfo
        title={`Hi ${userName}, Now choose the speaker for your audio experience. Which of these characters will you give the job to?`}
        description={`Tip: Choose a speaker whose style fits your brand best. Together with your script and sound experience, this will help you deliver your message perfectly matching ${businessName}.`}
      />

      <ChooseSpeaker
        handleChange={props.handleChange}
        values={props.values}
        nextStep={props.nextStep}
      />

      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons">
        <button className="button-secondary-aflr" onClick={back}>
          Back
        </button>
        <div className={classes}>Please select a speaker</div>
        <button className="button-aflr" onClick={saveAndContinue}>
          Next
        </button>
      </div>
    </div>
  );
}
