import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledTextField = withStyles((theme) => ({
  width: "400px",
}))(TextField);

function validateEmail(email) {
  if (email?.length === 0) return false;
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default (props) => {
  const { email, handleChange, shouldCheck, setError, setShouldCheck } = props;

  useEffect(() => {
    setError(!validateEmail(email));
    setShouldCheck(false);
  }, [email]);

  return (
    <FormControl>
      <StyledTextField
        className="mail-input"
        id="email"
        error={shouldCheck && !validateEmail(email)}
        helperText={
          shouldCheck && !validateEmail(email) && "Please check your email"
        }
        value={email}
        name="email"
        variant="outlined"
        onChange={handleChange("email")}
      />
    </FormControl>
  );
};
