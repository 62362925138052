import React from "react";
import "./AflrHeader.css";
import logo from "../../assets/img/Audiofunnel-logo2.png";

export default function AflrHeader() {
  return (
    <div className="header">
      <a href="https://audiofunnel.co/" target="_blank">
        <img className="logo" src={logo} alt={"logo"} />
      </a>
    </div>
  );
}
