import React, { useEffect, useState } from "react";
import "./steps.css";
import ChoosePlatform from "components/AflrUserInputComponents/ChoosePlatform";
import TitleAndInfo from "components/AflrReusableComponents/TitleAndInfo";
import EmailPopup from "components/AflrUserInputComponents/EmailPopup";
import ProgressBar from "components/AflrProgressBar/ProgressBar";

export default function Step5(props) {
  const { values } = props;
  const { userName } = values;
  const [classes, setClasses] = useState("error-hidden");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (values.platform !== "" && values.platform !== undefined) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.platform]);

  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <div>
      <TitleAndInfo
        title={`${userName}, tell us where you want your audio player to live. What is your website built with?`}
      />

      <ChoosePlatform
        handleChange={props.handleChange}
        values={values}
        disabled={disabled}
        setDisabled={setDisabled}
      />
      <ProgressBar step={props.values.step} />
      <div className="navigation-buttons">
        <button className="button-secondary-aflr" onClick={back}>
          Back
        </button>
        <div className={classes}>Please choose a platform</div>
        <EmailPopup
          nextStep={props.nextStep}
          handleChange={props.handleChange}
          values={values}
          description={`Almost Done! Where should we send your audio story to?`}
          buttonText="Next"
          popupButton="Send"
          beforeOpen={disabled}
        />
      </div>
    </div>
  );
}
