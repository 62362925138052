import React, { useState } from "react";
import Card from "components/Card/Card.js";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import Step7 from "./Step7";
import Step8 from "./Step8";
import ProgressBar from "../AflrProgressBar/ProgressBar";
import { useOrientation } from "../Hooks";
import AflrCharacterGenerator from "components/AflrCharacterGenerator/AflrCharacterGenerator";

/*
react global state problem
1. redux -> hard to learn, useful for larger - complex apps
2. react context -> easy to learn, can be hard to use when you have a complex app
*/

const MainForm = () => {
  const [state, _setState] = useState({
    step: 1,
    userId: "",
    email: "",
    template: "0",
    speaker: "",
    monthlyVisitors: "",
    personalization: "",
    platform: "",
    userName: "",
    businessName: "",
    userVertical: "nutrition expert",
    userGoal: "generate more leads",
    clientSecret: "",
    text: "",
    music: ""
  });
  const { step } = state;

  const widthThresold = 600;

  const { orientation, isHorizontal } = useOrientation(widthThresold);

  const setState = (newState) =>
    _setState((state) => {
      return { ...state, ...newState };
    });

  const generateUserId = () => {
    if (state.userId == "") {
      // always start with a letter (for DOM friendlyness)
      var idstr = String.fromCharCode(Math.floor(Math.random() * 25 + 65));
      do {
        // between numbers and characters (48 is 0 and 90 is Z (42-48 = 90)
        var ascicode = Math.floor(Math.random() * 42 + 48);
        if (ascicode < 58 || ascicode > 64) {
          // exclude all chars between : (58) and @ (64)
          idstr += String.fromCharCode(ascicode);
        }
      } while (idstr.length < 32);
      setState({ userId: idstr.toLowerCase() });
    }
  };

  const postUserInfo = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state)
    };
    fetch("https://audiofunnel.aflr.io/user", requestOptions).then((response) => response.json());
  };

  const nextStep = (increaseByANumber = false) => {
    postUserInfo();
    generateUserId();
    window.scrollTo(0, 0);
    const { step } = state;
    const increase = increaseByANumber ? increaseByANumber : 1;
    setState({
      step: step + increase
    });
  };

  const prevStep = () => {
    postUserInfo();
    window.scrollTo(0, 0);
    const { step } = state;
    setState({
      step: step - 1
    });
  };

  const handleChange = (input) => (event, value) => {
    if (!event?.target?.value) {
      if (typeof value === "number" || typeof value === "string") {
        event = { target: { value: value } };
      } else if (!value) {
        event = { target: "" };
      } else {
        alert("problem with the event sent to handleChange");
      }
    }
    setState({ [input]: event?.target?.value });
  };

  const setClientSecret = (clientSecret) => {
    setState({ clientSecret });
  };

  const setPaymentStatus = (paymentStatus) => {
    setState({ paymentStatus });
  };

  function renderStep() {
    switch (step) {
      case 1:
        return (
          <Step1
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 2:
        return (
          <Step2
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 3:
        return (
          <Step3
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 4:
        return (
          <Step4
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 5:
        return (
          <Step5
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 6:
        return (
          <Step6
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={state}
          />
        );
      case 7:
        return (
          <Step7
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={state}
            clientSecretState={[state.clientSecret, setClientSecret]}
            paymentStatusState={[state.paymentStatus, setPaymentStatus]}
          />
        );
      case 8:
        return (
          <Step8
            prevStep={prevStep}
            nextStep={nextStep}
            handleChange={handleChange}
            values={state}
          />
        );
      default:
        return <div>loading....</div>;
    }
  }

  return (
    <>
      {step < 7 && (
        <>
          <div className="step">{renderStep()}</div>
          <br />
        </>
      )}
      {step === 7 && <div>{renderStep()}</div>}

      {step === 1 && (
        <div className="aflr-power">
          Powered by&nbsp;
          <a
            href="https://aflorithmic.ai"
            target="_blank"
            style={{ color: "#fff", textDecoration: "underline" }}
          >
            Aflorithmic
          </a>
        </div>
      )}
    </>
  );
};

export default MainForm;
