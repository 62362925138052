import React, { useEffect } from "react";
import MainForm from "./components/Steps/MainForm";
import PaymentFailed from "./components/PaymentFailed/index";
import PaymentSuccess from "./components/PaymentSuccess/index";
import "./App.css";
import AflrHeader from "./components/AflrHeader/AflrHeader";
import { BrowserRouter, Switch, Router, Link, Route } from "react-router-dom";
import { hotjar } from "react-hotjar";

const App = () => {
  useEffect(() => {
    hotjar.initialize("2055218", 6);
  }, []);
  return (
    <>
      <AflrHeader />
      <div className="background"></div>
      <div className="grid-app">
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={MainForm} />
            <Route path="/success" component={PaymentSuccess} />
            <Route path="/fail" component={PaymentFailed} />
          </Switch>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
