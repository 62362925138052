import React, { useState, useEffect } from "react";
import "./AflrReusable.css";
import { min } from "moment";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import EmailPopup from "components/AflrUserInputComponents/EmailPopup";

export default function (props) {
  const {
    title,
    description,
    imgSrc,
    imgAlt,
    audio,
    minHeight,
    supportHover,
    popup,
  } = props;
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://aflr-assets.s3-eu-west-1.amazonaws.com/widget/bundle.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      {/* <Card>
        <cardTitle>{title}</cardTitle>
        <img src={imgSrc} alt={imgAlt} />
        <CardBody>{description}</CardBody>
        <div id="aflr_widget" data-url={audio} data-hidefooter="true"></div>
      </Card> */}
      <div
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          color: "#000",
          textTransform: "none",
          boxShadow: "0 0px 8px 0 #ccc",
          borderRadius: "6px",
        }}
      >
        <img
          style={{
            backgroundSize: "cover",
            marginTop: "-10px",
            width: "100%",
            padding: "0%",
            borderRadius: "5px 5px 0px 0px",
          }}
          src={imgSrc}
          alt={imgAlt}
        />
        <strong className="speaker-name">{title}</strong>
        {supportHover && isShown && (
          <div className="card-description">{description}</div>
        )}

        <div
          style={{
            marginTop: "5%",
            marginBottom: "5%",
          }}
        ></div>
        <div>
          {!supportHover && (
            <p
              style={{
                alignItems: "center",
                minHeight: minHeight,
                fontWeight: "500",
              }}
            >
              {description}
            </p>
          )}
        </div>
        {audio && (
          <div>
            <div
              id="aflr_widget"
              data-key="aflr"
              data-url={audio}
              data-hidefooter="true"
              style={{ width: "100%" }}
            ></div>
          </div>
        )}
        {popup && (
          <EmailPopup
            nextStep={props.nextStep}
            handleChange={props.handleChange}
            values={props.values}
            buttonText={"Contact us"}
            description={
              "Please, introduce your email and we will be in touch with you very soon"
            }
          />
        )}
      </div>
    </>
  );
}
