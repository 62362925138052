import React from "react";
import Button from "../CustomButtons/Button";
import "./steps.css";

export default function Step8(props) {
  const { userName, businessName } = props.values;
  return (
    <div>
      <br></br> <br></br>
      <div className="aflr-title" style={{ marginBottom: "41px" }}>
        {userName}, great job!
      </div>
      <div className="aflr-title" style={{ marginBottom: "41px" }}>
        Your audio story for {businessName} will be produced now.
      </div>
      <div className="aflr-title" style={{ marginBottom: "69px" }}>
        Our mighty team will get in contact with you for more details. :)
      </div>
      <button className="button-aflr" style={{ width: "fit-content" }}>
        <a style={{ color: "white" }} href="https://audiofunnel.co/">
          Go back to the main page
        </a>
      </button>
    </div>
  );
}
